import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getBadges = createAsyncThunk(
  "badge/getBadges",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, limit: params?.limit || 10, sort: "order" };
      const { data } = await getData("admin/badges", params);
      return { data: data.payload.badges, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createBadge = createAsyncThunk(
  "badge/createBadge",
  async (badge, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/badges", badge);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteBadge = createAsyncThunk(
  "badge/deleteBadge",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/badges", id);
      return { id };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateBadge = createAsyncThunk(
  "badge/updateBadge",
  async ({ id, badge }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/badges", id, badge);
      return data.payload.badge;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  badges: null,
  meta: null,
  error: null,
};

const badgesSlice = createSlice({
  name: "badges",
  initialState,
  reducers: {},
  extraReducers: {
    [getBadges.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getBadges.fulfilled]: (state, { payload }) => {
      state.badges = payload.data;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getBadges.rejected]: (state, { payload }) => {
      state.error = payload;
      state.badges = [];
      state.loading = false;
    },
    [createBadge.fulfilled]: (state, { payload }) => {
      state.badges = [...state.badges, payload.badge];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteBadge.fulfilled]: (state, { payload }) => {
      state.admins = state.badges.filter((badge) => badge._id !== payload.id);
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
    [updateBadge.fulfilled]: (state, { payload }) => {
      state.admins = state.badges.map((badge) =>
        badge._id === payload._id ? { ...payload } : { ...badge }
      );
    },
  },
});

export default badgesSlice.reducer;
