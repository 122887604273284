import { createSlice } from "@reduxjs/toolkit";
import { cleanObject } from "../../heplers/cleanObject";

const initialState = {
  filter: "",
  data: {},
};

const filterSlice = createSlice({
  name: "filter",
  initialState,
  reducers: {
    resetFilter(state) {
      state.filter = "";
      state.data = "";
    },
    setFilter(state, { payload }) {
      state.filter = cleanObject(payload);
    },
    setFilterData(state, { payload }) {
      state.data = payload;
    },
  },
});

export const { resetFilter, setFilter, setFilterData } = filterSlice.actions;

export default filterSlice.reducer;
