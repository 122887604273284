import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { transformAllVerb } from "../../heplers/transformAllVerb";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getRoles = createAsyncThunk(
  "admin/getRoles",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/roles", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createRole = createAsyncThunk(
  "admin/createRole",
  async (newRole, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/roles", newRole);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteRole = createAsyncThunk(
  "admin/deleteRole",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/roles", id);
      return { id };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateRole = createAsyncThunk(
  "admin/updateRole",
  async ({ id, role }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/roles", id, role);
      return data.payload.role;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const updateRolePermissions = createAsyncThunk(
  "admin/updateRolePermissions",
  async ({ id, permissions }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne(
        `admin/roles/${id}/update-role-permissions`,
        permissions
      );
      return data.payload.role;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  roles: [],
  role: null,
  verbs: null,
  permissions: [],
  meta: null,
  error: null,
};

const roleSlice = createSlice({
  name: "roles",
  initialState,
  reducers: {
    addPermission(state, { payload }) {
      const existPer = state.permissions.find(
        (per) => payload.permission.resource === per.resource
      );
      if (existPer) {
        state.permissions = state.permissions.map((per) =>
          per.resource === payload.permission.resource
            ? payload.permission
            : per
        );
      } else {
        let copy = [...state.permissions];
        state.permissions = [...copy, payload.permission];
      }
    },
    setPermissions(state, { payload }) {
      const formatPermissions = payload.permissions?.map((p) => {
        let perCopy = { ...p };
        let verbsCopy = perCopy.verbs;
        let transformedVerbs = transformAllVerb(verbsCopy);
        return {
          ...perCopy,
          verbs: transformedVerbs,
        };
      });

      state.permissions = formatPermissions;
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getRoles.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getRoles.fulfilled, (state, { payload }) => {
        state.roles = payload.payload.roles;
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(getRoles.rejected, (state, { payload }) => {
        state.error = payload;
        state.roles = [];
        state.loading = false;
      })
      .addCase(createRole.fulfilled, (state, { payload }) => {
        state.roles = [...state.roles, payload];
        state.meta = { ...state.meta, totalDocs: state.meta?.totalDocs + 1 };
      })
      .addCase(updateRole.fulfilled, (state, { payload }) => {
        state.roles = state.roles.map((role) =>
          role._id === payload._id ? { ...payload } : { ...role }
        );
      })
      .addCase(deleteRole.fulfilled, (state, { payload }) => {
        state.roles = state.roles.filter((role) => role._id !== payload.id);
        state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
      })
      .addCase(updateRolePermissions.fulfilled, (state, { payload }) => {
        state.roles = state.roles.map((role) =>
          role._id === payload._id ? { ...payload } : { ...role }
        );
      });
  },
});
export const { addPermission, setPermissions } = roleSlice.actions;

export default roleSlice.reducer;
