import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { useLocation } from "react-router-dom";
import ModalForm from "../../components/modalForm/modalForm";
import PopupUploadLibrary from "../../components/uploalLibrary/uploadLibrary";
import { resetFilter } from "../../store/slices/globalFilter";
import { resetFile } from "../../store/slices/library";
import GlobalSearch from "../../views/globalSearch/GlobalSearch";
import Header from "./components/header/Header";
import SideBar from "./components/sideBar/SideBar";

const MainLayout = ({ children }) => {
  const dispatch = useDispatch();
  const { pathname } = useLocation();
  const [value, setValue] = useState("");
  const [isOpen, setIsOpen] = useState(false);
  const { library } = useSelector((state) => state.library);

  useEffect(() => {
    setValue(null);
    dispatch(resetFilter());
    dispatch(resetFile());
  }, [pathname]);

  return (
    <div className="layout">
      <ModalForm />
      {library && <PopupUploadLibrary />}
      <SideBar setIsOpen={setIsOpen} isOpen={isOpen} />
      <div
        className="page-wrapper"
        style={
          !isOpen
            ? { maxWidth: "calc(100% - 229px)" }
            : { maxWidth: "calc(100% - 71px)" }
        }
      >
        <Header onSerach={(value) => setValue(value)} />
        {value && value.trim().length >= 3 ? (
          <GlobalSearch value={value} setValue={setValue} />
        ) : (
          children
        )}
      </div>
    </div>
  );
};

export default MainLayout;
