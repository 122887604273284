import { Pagination } from "@mui/material";
import React from "react";

function Paggination({ count, onChange, setLimit }) {
  return (
    <div className="paggination">
      <select
        onChange={(e) => setLimit(e?.target.value)}
        className="paggination__limit"
      >
        <option>25</option>
        <option>50</option>
        <option>100</option>
        <option>1000</option>
      </select>
      <Pagination
        count={count}
        color="primary"
        onChange={onChange}
        showFirstButton
        showLastButton
      ></Pagination>
    </div>
  );
}

export default Paggination;
