import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { addOrder } from "../../heplers/addOrder";
import { reorderData } from "../../heplers/reorderData";
import { createOne, deleteOne, updateOne } from "../../services/protectApi";

export const updateBlock = createAsyncThunk(
  "question/updateBlock",
  async ({ id, Block }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/question-blocks", id, Block);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const createBlock = createAsyncThunk(
  "question/createBlock",
  async (Block, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/question-blocks", Block);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteBlock = createAsyncThunk(
  "question/deleteBlock",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/question-blocks", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateBlocks = createAsyncThunk(
  "question/updateBlocks",
  async (blocks, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/question-blocks/create", blocks);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  questionBlocksForm: [],
  availableBlocks: [],
};
const repCodes = [5, 6];
const questionBlocksFormSlice = createSlice({
  name: "questionBlocksForm",
  initialState,
  reducers: {
    initialQuestionBlock(state, { payload }) {
      state.questionBlocksForm = payload;
    },
    AddQuestionBlock(state, { payload }) {
      const newBlock = { ...payload, data: {} };
      const { code } = newBlock;
      const blocksCode = state.questionBlocksForm;

      if (
        !repCodes.includes(code) &&
        blocksCode.find((block) => block.code == code)
      ) {
        return;
      }

      // if (code >= 7 && blocksCode.some((code) => code >= 7)) {
      //   return;
      // }

      state.questionBlocksForm = [
        ...state.questionBlocksForm,
        { ...newBlock, order: state.questionBlocksForm?.length + 1 },
      ];
    },
    resetQuestionBlockForm(state) {
      state.questionBlocksForm = [];
    },
    removeQuestionBlock(state, { payload }) {
      const BlockId = payload;
      const newBlocks = state.questionBlocksForm.filter(
        (block) => block.id !== BlockId
      );
      state.questionBlocksForm = addOrder([...newBlocks]);
    },
    updateQuestion(state, { payload }) {
      const { id, data, error } = payload;
      const updatedBlocks = state.questionBlocksForm.map((block) =>
        block.id == id || block._id == id
          ? { ...block, data, error }
          : { ...block }
      );
      state.questionBlocksForm = updatedBlocks;
    },
    reorderBlocks(state, { payload }) {
      const { target, source } = payload;
      const questionBlocks = state.questionBlocksForm;
      if (target > questionBlocks.length || target < 1) return;
      state.questionBlocksForm = reorderData(
        { index: source },
        { index: target },
        questionBlocks
      );
    },
  },
  extraReducers: {
    [deleteBlock.fulfilled]: (state, { payload }) => {
      const blockId = payload;
      const newBlocks = state.questionBlocksForm.filter(
        (block) => block._id !== blockId
      );
      state.questionBlocksForm = addOrder([...newBlocks]);
    },
  },
});

export const questionBlocksFormActions = questionBlocksFormSlice.actions;

export default questionBlocksFormSlice.reducer;
