import React, { useEffect } from "react";
import { useApiErrorContext } from "../../context/apiErrorContext";
import { toast, ToastContainer } from "react-toastify";
import "react-toastify/dist/ReactToastify.css";

const PopupTime = { success: 500, error: 3000 };

function ErrorModal() {
  const {
    message = "request fail",
    type = "success",
    random = Math.random(),
  } = useApiErrorContext();
  const notify = () => toast[type.toLocaleLowerCase()](message);
  useEffect(() => {
    if (message != "") notify();
  }, [message, random]);
  return (
    <div className="">
      <ToastContainer
        position="top-left"
        autoClose={PopupTime[type.toLocaleLowerCase()]}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
        pauseOnHover
        theme="light"
      />
    </div>
  );
}

export default ErrorModal;
