import React, { useEffect, useState } from 'react';
import logoIcon from './../../../../assets/icons/logo2.png';
import leftIcon from './../../../../assets/icons/left-long-solid.svg';
import { useScrollWithShadow } from '../../../../hooks/useScrollWithShadow';
import Languages from './components/languages/languages';
import { PAGES } from '../../../../constant/routes/sideBarRoutes';
import SideBarItem from './components/SidebarItem/SidebarItem';
import { useNavigate } from 'react-router-dom';
import { PATH } from '../../../../constant/routes/paths';
import { Can } from '../../../../context/abilityContext';

function SideBar({ setIsOpen, isOpen }) {
  const { boxShadow, onScrollHandler } = useScrollWithShadow();
  const navigate = useNavigate();
  return (
    <div className="sidebar" id={isOpen ? 'closeSideBar' : ''}>
      <div className="sidebar__content">
        <div className="sidebar__content-menu">
          <div
            className="sidebar__content-menu-logo"
            onClick={() => navigate(PATH.DASHBOARD)}
          >
            <img src={logoIcon} alt="logo icon error" />
          </div>
          <div className="sidebar__content-menu-navlist">
            <ul onScroll={onScrollHandler} style={{ boxShadow }}>
              {PAGES.map((route) =>
                route.permissions ? (
                  <Can
                    I={route.permissions?.verb}
                    a={route.permissions?.resource}
                    key={route.id}
                  >
                    <SideBarItem
                      route={route}
                      setIsOpen={setIsOpen}
                      isOpen={isOpen}
                    />
                  </Can>
                ) : (
                  <SideBarItem
                    route={route}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    key={route.id}
                  />
                )
              )}
            </ul>
          </div>
        </div>
        <Languages />
      </div>
      <div
        className="sidebar__collapse"
        onClick={() => {
          setIsOpen(!isOpen);
        }}
      >
        {isOpen ? (
          <img
            src={leftIcon}
            alt=""
            className="sidebar__collapse-icon rotate"
          />
        ) : (
          <>
            <img src={leftIcon} alt="" className="sidebar__collapse-icon" />
            Collapse sidebar
          </>
        )}
      </div>
    </div>
  );
}

export default SideBar;
