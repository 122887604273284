import ability from "./ability";
import { createContext } from "react";
import { createContextualCan } from "@casl/react";
import { useAuth } from "./authContext";

export const AbilityContext = createContext();
export const Can = createContextualCan(AbilityContext.Consumer);

export default function AbilityProvider({ children }) {
  const { isAuthenticated, user } = useAuth();

  return (
    <AbilityContext.Provider value={ability(isAuthenticated, user)}>
      {children}
    </AbilityContext.Provider>
  );
}
