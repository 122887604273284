import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getRevisions = createAsyncThunk(
  "revesion/getRevisions",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/revisions", params);
      return { data: data.payload.revisions, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createRevisions = createAsyncThunk(
  "revesion/createRevisions",
  async (revision, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/revisions", revision);
      return data.payload.revision;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteRevisions = createAsyncThunk(
  "revesion/deleteRevisions",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/revisions", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateRevision = createAsyncThunk(
  "revesion/updateRevision",
  async ({ id, revision }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/revisions", id, revision);
      return data.payload.revision;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  revisions: [],
  meta: null,
  error: null,
};

const revisionsSlice = createSlice({
  name: "revisions",
  initialState,
  reducers: {},
  extraReducers: {
    [getRevisions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getRevisions.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.revisions = data;
      state.meta = meta;
      state.loading = false;
    },
    [getRevisions.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
    [createRevisions.fulfilled]: (state, { payload }) => {
      state.revisions = [...state.revisions, payload];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteRevisions.fulfilled]: (state, { payload }) => {
      state.revisions = state.revisions.filter(
        (revesion) => revesion._id !== payload
      );
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
    [updateRevision.fulfilled]: (state, { payload }) => {
      state.revisions = state.revisions.map((revision) =>
        revision._id == payload?._id ? { ...payload } : { ...revision }
      );
    },
  },
});

export const {} = revisionsSlice.actions;

export default revisionsSlice.reducer;
