import React, { Fragment, useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { FieldTypes } from "../../constant/filtredFieldTable";
import { setFilter } from "../../store/slices/globalFilter";
import downIcon from "./../../assets/icons/caret-down-solid.svg";

const formatOptions = (options) => {
  return options?.map((option) => {
    const firstKey = Object.keys(option)[0];
    const secondKey = Object.keys(option)[1];
    return { label: option[firstKey], value: option[secondKey] };
  });
};

const Input = ({ onChange, type = "text", defaultValue }) => {
  const setData = (e) => {
    const delayDebounceFn = setTimeout(() => {
      onChange(e.target?.value);
    }, 500);
  };
  return (
    <input
      onChange={setData}
      className="filterInputText"
      placeholder="Type here"
      type={type}
      defaultValue={defaultValue}
    />
  );
};

const Select = ({ onChange, options, defaultValue }) => {
  const setData = (e) => onChange(e.target?.value);
  console.log({ newDatat: formatOptions(options) });
  return (
    <select onChange={setData} className="filterSelect">
      {formatOptions(options)
        ?.map((option) => {
          return { label: option?.label, value: option?.value };
        })
        .map((option) => (
          <option
            value={option?.value}
            selected={defaultValue == option?.value ? "selected" : ""}
          >
            {option?.label}
          </option>
        ))}
    </select>
  );
};

const Date = ({ onChange, value }) => {
  const [startDate, setStartDate] = useState("");
  const [endDate, setEndDate] = useState("");
  useEffect(() => {
    if (startDate && endDate)
      onChange({ [value + "[gte]"]: startDate, [value + "[lte]"]: endDate });
  }, [startDate, endDate]);

  return (
    <>
      <span className="label">Start</span>
      <Input onChange={setStartDate} type="date" />
      <span className="label">End</span>
      <Input onChange={setEndDate} type="date" />
    </>
  );
};

const filterFieldTypes = {
  [FieldTypes.INPUT]: Input,
  [FieldTypes.SELECT]: Select,
  [FieldTypes.DATE]: Date,
};

const FileldItem = ({ field, type, setFilter, value, data = null, filter }) => {
  const [open, setOpen] = useState(false);
  const defaultValue = filter[value];
  console.log({ data });
  const Component = filterFieldTypes[type] || Fragment;
  return (
    <div className="">
      <div
        className="global__filter-container-field"
        onClick={() => setOpen(!open)}
      >
        <p>{field}</p>
        <img src={downIcon} className="downIcon" />
      </div>
      <div
        className={`global__filter-container-field-content ${
          open || defaultValue ? "FilterOpen" : ""
        }`}
      >
        <Component
          onChange={setFilter}
          options={data}
          value={value}
          defaultValue={defaultValue}
        />
      </div>
    </div>
  );
};

function GlobalFilter({ filterFields }) {
  const { data, filter } = useSelector((state) => state.filter);
  const dispatch = useDispatch();
  const setFilterObj = (value, valueKey) => {
    let query = { ...filter };
    if (valueKey && typeof value !== "object")
      query = { ...query, [valueKey]: value };
    else query = { ...query, ...value };
    dispatch(setFilter(query));
  };
  return (
    <div className="global__filter">
      {filterFields?.map((col, i) => {
        return (
          <div className="global__filter-container" key={i}>
            <FileldItem
              field={col?.field}
              type={col?.type}
              setFilter={(value) => setFilterObj(value, col?.value)}
              data={col?.data ? col?.data : data[col?.value] || data}
              value={col?.value}
              filter={filter}
            />
          </div>
        );
      })}
    </div>
  );
}

export default GlobalFilter;
