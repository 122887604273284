import { useEffect, useState } from 'react';
import { NavLink } from 'react-router-dom';
import { Can } from '../../../../../../context/abilityContext';
import subLinkIcon from './../../../../../../assets/icons/subLink.svg';

const SideBarItem = ({ route, setIsOpen: setSideBarOpen, isOpen }) => {
  const [open, setOpen] = useState(false);

  useEffect(() => {
    if (isOpen) setOpen(false);
  }, [isOpen]);

  return (
    <li>
      {route.routes ? (
        <>
          <div
            className={`link-container ${open ? 'activeRoute' : ''}`}
            onClick={() => {
              setSideBarOpen(false);
              setOpen(!open);
            }}
          >
            <div className="link">
              <div className="icon-link">
                <img src={route.icon} alt="link icon error" />
              </div>
              <span>{route.label}</span>
            </div>
            <div className={`subLinks ${open ? 'openLink' : ''}`}>
              <img src={subLinkIcon} alt="error" />
            </div>
          </div>
          {open ? (
            <div className="subLinks-conainer">
              {route.routes.map((subRoute, i) => (
                <Can
                  I={subRoute.permissions?.verb}
                  a={subRoute.permissions?.resource}
                  key={i}
                >
                  <NavLink
                    to={subRoute.link}
                    className={({ isActive }) =>
                      isActive
                        ? 'activeSubLink subLinks-conainer_subLink'
                        : 'subLinks-conainer_subLink'
                    }
                  >
                    <span>{subRoute.label}</span>
                  </NavLink>
                </Can>
              ))}
            </div>
          ) : (
            ''
          )}
        </>
      ) : (
        <NavLink
          to={route.link}
          className={({ isActive }) => (isActive ? 'activeLink' : '')}
        >
          <div className="link-container">
            <div className="link">
              <div className="icon-link">
                <img src={route.icon} alt="link icon error" />
              </div>
              <span>{route.label}</span>
            </div>
          </div>
        </NavLink>
      )}
    </li>
  );
};

export default SideBarItem;
