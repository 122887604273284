import { configureStore } from "@reduxjs/toolkit";
import user from "./slices/user";
import languages from "./slices/languages";
import questionBlockType from "./slices/questionBlockType";
import questionBlocksForm from "./slices/questionBlocksForm";
import admin from "./slices/admin";
import countries from "./slices/countries";
import chapters from "./slices/chapters";
import lessons from "./slices/lessons";
import levels from "./slices/levels";
import exercices from "./slices/exercices";
import exercicesTypes from "./slices/exerciceTypes";
import questions from "./slices/questions";
import keywords from "./slices/keywords";
import revisions from "./slices/revisions";
import notifications from "./slices/notifications";
import reports from "./slices/reports";
import globalSearch from "./slices/globalSearch";
import badges from "./slices/badges";
import filter from "./slices/globalFilter";
import categories from "./slices/categories";
import images from "./slices/images";
import library from "./slices/library";
import roles from "./slices/roles";
import resources from "./slices/resources";

const store = configureStore({
  reducer: {
    user,
    admin,
    languages,
    questionBlockType,
    questionBlocksForm,
    countries,
    chapters,
    lessons,
    levels,
    exercices,
    exercicesTypes,
    questions,
    keywords,
    revisions,
    notifications,
    reports,
    globalSearch,
    badges,
    categories,
    images,
    filter,
    library,
    roles,
    resources
  },
  middleware: (getDefaultMiddleware) =>
    getDefaultMiddleware({
      serializableCheck: false,
    }),
});

export default store;
