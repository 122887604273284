import React, { useState } from "react";
import { Form, Formik } from "formik";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import * as Yup from "yup";
import { duplicateLesson, getLessons } from "../../../../store/slices/lessons";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import Loading from "../../../../components/loadin/Loading";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import { useSelector } from "react-redux";

function LessonFormHandler() {
  const { levels } = useSelector((state) => state.levels);
  const { hidePopup, id, item, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const { id: courseId, levelId } = useParams();
  const { filter } = useSelector((state) => state.filter);
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    lessonId: Yup.string().required("Subtitle is required"),
    levelId: Yup.string().required("Level is required"),
  });

  let query = { courseId, levelId };

  const submitForm = (values) => {
    const { levelId, lessonId } = values;

    const duplicateLessonData = { levelId, lessonId };
    setLoading(true);
    dispatch(duplicateLesson(duplicateLessonData)).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`lesson duplicated successfully`, "success");
        query = { ...query, ...filter };
        dispatch(getLessons(query));
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  const levelOptions =
    levels && levels.length > 0
      ? levels
          ?.filter((level) => level.courseId._id !== courseId)
          .map((level) => ({
            label: level.title,
            value: level._id,
          }))
      : [];

  return (
    <div className="lessonForm customForm duplicate-form">
      <Formik
        initialValues={{
          lessonTitle: item.title || "",
          lessonId: item._id || "",
          levelId: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputSelect
                  options={levelOptions}
                  label="Select a level to duplicate into:"
                  placeholder="Levels"
                  required={true}
                  name="levelId"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `duplicate lesson`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LessonFormHandler;
