import React from "react";
import { Link } from "react-router-dom";

const tableRoles = ({ data }) => {
  return (
    <div className="rolesContainer" style={{ position: "relative" }}>
      {data ? (
        data?.roles?.map((role) => (
          <Link to={`/roles/form/${role._id}`} key={role._id} className="role">
            {role?.name}
          </Link>
        ))
      ) : (
        <p>Empty!</p>
      )}
    </div>
  );
};

export default tableRoles;
