import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getCountries = createAsyncThunk(
  "countries/getCountries",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/countries", params);
      return { data: data.payload, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const createCountry = createAsyncThunk(
  "countries/createCountry",
  async (country, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/countries", country);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteCountry = createAsyncThunk(
  "countries/deleteCountry",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/countries", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateCountry = createAsyncThunk(
  "countries/updateCountry",
  async ({ id, country }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/countries", id, country);
      return data.payload.country;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  countries: null,
  error: null,
  meta: null,
};

const countriesSlice = createSlice({
  name: "countries",
  initialState,
  reducers: {},
  extraReducers: {
    [getCountries.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCountries.fulfilled]: (state, { payload }) => {
      state.countries = payload.data.countries;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getCountries.rejected]: (state, { payload }) => {
      state.error = payload;
      state.countries = [];
      state.loading = false;
    },
    [createCountry.fulfilled]: (state, { payload }) => {
      state.countries = [...state.countries, payload.country];
    },
    [deleteCountry.fulfilled]: (state, { payload }) => {
      state.countries = state.countries.filter(({ _id }) => _id !== payload);
    },
    [updateCountry.fulfilled]: (state, { payload }) => {
      state.countries = state.countries.map((country) =>
        country._id == payload._id ? { ...payload } : { ...country }
      );
    },
  },
});

export default countriesSlice.reducer;
