import { ErrorMessage, Field, FieldArray, getIn, useField } from "formik";
import React, { useState } from "react";
import Button from "../../button/Button";
import addIcon from "./../../../assets/icons/Add.png";

function InputArrayText({
  defaultValue,
  formik,
  label,
  required,
  placeholder,
  ...props
}) {
  const [field] = useField(props);

  return (
    <div className="inputArray customInput">
      <label className="label">
        {label}
        {required && <span>*</span>}
      </label>
      <div className="inputContainer">
        <FieldArray
          validateOnBlur={true}
          name={field?.name}
          render={(arrayItems) => {
            return formik.values[field.name] &&
              formik.values[field.name].length > 0 ? (
              formik.values[field.name].map((friend, index) => {
                const error = field?.name
                  ? formik.errors[field?.name]
                    ? formik.errors[field?.name][index]
                      ? formik.errors[field?.name][index]
                      : null
                    : null
                  : null;
                const lengthValues = formik.values[field.name].length;
                const last = lengthValues - 1 == index;
                return (
                  <div key={index} className="inputContainer--add">
                    <Field name={field.name + `.${index}`}>
                      {({ field, meta }) => (
                        <>
                          <input
                            type="text"
                            {...field}
                            placeholder={placeholder}
                            className={
                              meta.touched && meta.error ? "is-invalid" : ""
                            }
                          />
                          {error && meta.touched && (
                            <p className="error">{error}</p>
                          )}
                        </>
                      )}
                    </Field>
                    {last && (
                      <>
                        <Button
                          onClick={() => arrayItems.insert(index, "")}
                          label="+"
                        />

                        <Button
                          onClick={() =>
                            index !== 0 && arrayItems.remove(index)
                          }
                          label="-"
                        />
                      </>
                    )}
                  </div>
                );
              })
            ) : (
              <button type="button" onClick={() => arrayItems.push("")}>
                Add a friend
              </button>
            );
          }}
        ></FieldArray>
      </div>
    </div>
  );
}

export default InputArrayText;
