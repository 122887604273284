import React, { useEffect, useState } from "react";
import ImageDataSection from "./components/imageDataSection/imageDataSection";
import ImageSection from "./components/imagesSection/imageSection";
import closeIcon from "./../../assets/icons/Close.png";
import LibrarySection from "./components/librarySection/librarySection";
import UploadSection from "./components/uploadSection/uploadSection";
import { useDispatch } from "react-redux";
import { closeLibrary, setFile } from "../../store/slices/library";

const switchedList = [
  { id: "up", label: "Upload" },
  { id: "li", label: "Library" },
];

function PopupUploadLibrary({ setVal, fromB = false, closePopup }) {
  const dispatch = useDispatch();
  const [active, setActive] = useState("li");
  const [folder, setFolderID] = useState(null);
  const [selectedFile, setSelectedFile] = useState(null);
  const [uploadedFile, setUploadedFile] = useState(null);
  const switchLink = () => setActive(active === "up" ? "li" : "up");

  useEffect(() => {
    if (uploadedFile) {
      if (fromB) {
        closePopup();
        setVal(uploadedFile);
      } else dispatch(setFile(uploadedFile));
      dispatch(closeLibrary());
    }
  }, [uploadedFile]);

  const closePopupHandler = () => {
    if (fromB) closePopup();
    else dispatch(closeLibrary());
  };

  const openFolder = (id) => setFolderID(id);

  return (
    <>
      <div className="backscreen" onClick={closePopupHandler}></div>
      <div className="library">
        <div className="library__header">
          <p className="library__header-exit" onClick={closePopupHandler}>
            <img src={closeIcon} />
          </p>
          <ul>
            {switchedList.map((link) => (
              <li
                key={link.id}
                onClick={switchLink}
                className={active === link?.id ? "active" : ""}
              >
                {link?.label}
              </li>
            ))}
          </ul>
        </div>
        <div className="library__content">
          {active == "up" ? (
            selectedFile ? (
              <ImageDataSection
                file={selectedFile}
                setFile={setSelectedFile}
                onUpload={setUploadedFile}
              />
            ) : (
              <UploadSection onSelect={setSelectedFile} />
            )
          ) : folder ? (
            <ImageSection
              folder={folder}
              setFolderId={setFolderID}
              onUpload={setUploadedFile}
            />
          ) : (
            <LibrarySection onClick={openFolder} />
          )}
        </div>
      </div>
    </>
  );
}

export default PopupUploadLibrary;
