import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useModalFormContext } from "../../../../context/modalFormContext";
import * as Yup from "yup";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import Loading from "../../../../components/loadin/Loading";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import { useSelector } from "react-redux";
import { getChapters } from "../../../../store/slices/chapters";
import { createLevel, updateLevel } from "../../../../store/slices/levels";
import { useParams } from "react-router-dom";

function LevelFormHandler() {
  const dispatch = useDispatch();
  const { id: courseId } = useParams();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { item, hidePopup, type, id } = useModalFormContext();
  const { chapters } = useSelector((state) => state.chapters);
  const { currentLanguage } = useSelector((state) => state.languages);

  const validate = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    courseId: Yup.string().required("Course is required"),
  });

  useEffect(() => {
    dispatch(getChapters({ languageId: currentLanguage._id }));
  }, []);

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      item ? updateLevel({ id, level: values }) : createLevel(values)
    ).then((res) => {
      setLoading(false);
      if (res.error) showNotif(res?.payload?.data?.message);
      else {
        showNotif(`level ${type}ed successfully`, "success");
        hidePopup();
      }
    });
  };

  const options = chapters.map((option) => {
    return { label: option.title, value: option._id };
  });

  return (
    <div className="exerciceType customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          courseId: item?.courseId._id || courseId || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Level TItle"
                  placeholder="Add your level name"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputSelect
                  label="Level Course"
                  placeholder="Add your level name"
                  required={true}
                  name="courseId"
                  options={options}
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} level`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LevelFormHandler;
