import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getCategories = createAsyncThunk(
  "categories/getCategories",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, limit: params?.limit || 10 };
      const { data } = await getData("admin/image-categories", params);
      return { data: data.payload.imageCategories, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createCategory = createAsyncThunk(
  "categories/createCategory",
  async (category, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/image-categories", category);
      return data.payload.imageCategory;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateCategory = createAsyncThunk(
  "categories/updateCategory",
  async ({ id, category }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/image-categories", id, category);
      return data.payload.imageCategory;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  categories: [],
  meta: null,
  error: null,
};

const categoriesSlice = createSlice({
  name: "categories",
  initialState,
  reducers: {
    resetCategories(state) {
      state.categories = [];
    },
  },
  extraReducers: {
    [getCategories.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getCategories.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.categories = data;
      state.meta = meta;
      state.loading = false;
    },
    [getCategories.rejected]: (state, { payload }) => {
      state.error = payload;
      state.categories = [];
      state.loading = false;
    },
    [createCategory.fulfilled]: (state, { payload }) => {
      state.categories = [...state.categories, payload];
      state.meta = { ...state.meta, totalDocs: state?.meta?.totalDocs + 1 };
    },
    [updateCategory.fulfilled]: (state, { payload }) => {
      state.categories = state.categories.map((category) =>
        category._id == payload._id ? { ...payload } : { ...category }
      );
    },
  },
});

export const { resetCategories } = categoriesSlice.actions;

export default categoriesSlice.reducer;
