import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { FormButton } from "../../../../views/admins/components/form/adminFormHandler";
import InputText from "../../../formInputTypes/inputText/InputText";
import * as Yup from "yup";
import leftIcon from "./../../../../assets/icons/left-long-solid.svg";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import { getCategories } from "../../../../store/slices/categories";
import InputSelect from "../../../formInputTypes/inputSelect/InputSelect";
import { createImage } from "../../../../store/slices/images";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { useApiErrorContext } from "../../../../context/apiErrorContext";

function ImageDataSection({ file, setFile, onUpload }) {
  const dispatch = useDispatch();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState(false);
  const { categories, error } = useSelector((state) => state.categories);
  const validate = Yup.object().shape({
    name: Yup.string().required("Name is required"),
    category: Yup.string().required("Category is required"),
  });
  useEffect(() => {
    dispatch(getCategories({ limit: -1 }));
  }, []);

  const options = categories?.map((category) => {
    return { label: category?.name, value: category?._id };
  });

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      createImage({
        categoryID: values?.category,
        image: convertObjectToFormData({ name: values?.name, img: file }),
      })
    ).then((res) => {
      setLoading(false);
      if (res?.error) showNotif(res?.payload?.data?.message);
      else {
        setFile(null);
        showNotif("image uploaded successfully", "success");
        onUpload(res?.payload);
      }
    });
  };
  const fileURL = URL.createObjectURL(file);

  return (
    <div className="library__content-upload ">
      <img
        src={leftIcon}
        className="library__content-upload-back"
        onClick={() => setFile(null)}
      />
      <div className="image_data">
        <img src={fileURL} className="image_data-uploaded" />
        <Formik
          initialValues={{
            name: "",
            category: "",
          }}
          validationSchema={validate}
          onSubmit={(values) => submitForm(values)}
        >
          {(formik) => {
            return (
              <Form>
                <div className="inputContainer">
                  <InputText
                    label="Name Image"
                    placeholder="Add Name Image"
                    required={true}
                    name="name"
                    type="text"
                  />

                  <InputSelect
                    label="Category"
                    placeholder="Select Your Category"
                    required={true}
                    name="category"
                    options={options}
                  />
                </div>
                <FormButton label={loading ? "uploading..." : "upload"} />
              </Form>
            );
          }}
        </Formik>
      </div>
    </div>
  );
}

export default ImageDataSection;
