import React, { useEffect, useState } from "react";
import Button from "../../../components/button/Button";
import addIcon from "./../../../assets/icons/Add.png";
import filterIcon from "./../../../assets/icons/Setting.png";
import columnIcon from "./../../../assets/icons/Columns.png";
import settingIcon from "./../../../assets/icons/Setting - 2.svg";
import GlobalFilter from "../../../components/GlobalFilter/GlobalFilter";
import GlobalColumnControll from "../../../components/GlobalColumnControll/GlobalColumnControll";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../../context/abilityContext";

function Menu({
  title,
  description,
  number,
  switchList = [],
  labelButton,
  onClick,
  setActive,
  order,
  filter,
  onOrder,
  filterFields,
  columnFields = [],
  setColumns,
  permissions,
}) {
  const [filterOpen, setFilterOpen] = useState(false);
  const [columnOpen, setColumnOpen] = useState(false);
  const ability = useAbility(AbilityContext);
  return (
    <div
      className="menu"
      style={{
        paddingBottom: switchList?.length > 0 ? "0" : "16px",
      }}
    >
      <div className="menu__first-section">
        <div className="menu__first-section-header">
          <p>{title}</p>
        </div>
        <div className="menu__first-section-listType">
          <p>{description}</p>
          {number !== undefined && <span>{number}</span>}
        </div>
        {switchList?.length > 0 && (
          <div className="menu__first-section-switch-list">
            <ul>
              {switchList?.map((item, i) => {
                return (
                  <li
                    key={item._id}
                    className={item.active ? "activeList" : ""}
                    onClick={() => {
                      setActive(item._id);
                    }}
                  >
                    {item.title}
                  </li>
                );
              })}
            </ul>
          </div>
        )}
      </div>
      <div className="menu-second-section">
        <div
          className="menu-second-section-buttons"
          style={{ paddingBottom: switchList?.length > 0 ? "14px" : "0" }}
        >
          {order && (
            <div className="order">
              <Button icon={settingIcon} label="Save Order" onClick={onOrder} />
            </div>
          )}
          {columnFields?.length > 0 && (
            <>
              {columnOpen && (
                <div
                  className="backscreen"
                  onClick={() => setColumnOpen(false)}
                ></div>
              )}
              <div className="order filter">
                <Button
                  icon={columnIcon}
                  label="Columns"
                  onClick={() => setColumnOpen(!columnOpen)}
                />
                {columnOpen && (
                  <GlobalColumnControll
                    columnFields={columnFields}
                    setSelectedFields={setColumns}
                  />
                )}
              </div>
            </>
          )}
          {filter && (
            <>
              {filterOpen && (
                <div
                  className="backscreen"
                  onClick={() => setFilterOpen(false)}
                ></div>
              )}
              <div className="order filter" onBlur={() => {}}>
                <Button
                  icon={filterIcon}
                  label="Filter"
                  onClick={() => setFilterOpen(!filterOpen)}
                />
                {filterOpen && <GlobalFilter filterFields={filterFields} />}
              </div>
            </>
          )}
          {ability.can(permissions.verb, permissions.resource) &&
            (onClick ? (
              <Button
                icon={addIcon}
                label={labelButton}
                onClick={() =>
                  onClick("test", "create admin", "create new admin")
                }
              />
            ) : null)}
        </div>
      </div>
    </div>
  );
}

export default Menu;
