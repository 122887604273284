import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputSelectMultiple from "../../../../components/formInputTypes/inputSelectMultiple/InputSelectMultiple";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import {
  createCountry,
  updateCountry,
} from "../../../../store/slices/countries";
import { FormButton } from "../../../admins/components/form/adminFormHandler";

const options = [
  {
    label: "by email",
    value: {
      isEmailRegistrationAvailable: true,
      isPhoneRegistrationAvailable: false,
    },
  },
  {
    label: "by sms",
    value: {
      isEmailRegistrationAvailable: false,
      isPhoneRegistrationAvailable: true,
    },
  },
  {
    label: "by sms and email",
    value: {
      isEmailRegistrationAvailable: true,
      isPhoneRegistrationAvailable: true,
    },
  },
];

function CountryFormHandler() {
  const validate = Yup.object().shape({
    flag: Yup.string().required("flag is required"),
    name: Yup.string().required("Name is required"),
    code: Yup.string().required("Code is required"),
    ISOCode: Yup.string().required("Abbreviations is required"),
  });
  const { hidePopup, item, type, id } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  const submitForm = (values) => {
    setLoading(true);
    const { Validate } = values;
    delete values.Validate;
    const country = convertObjectToFormData({ ...values, ...Validate.value });
    dispatch(
      item ? updateCountry({ id, country }) : createCountry(country)
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        showNotif(`country ${type}ed successfully`, "success");
        hidePopup();
      } else showNotif(res.payload.data.message);
    });
  };

  const validateCountry = {
    isEmailRegistrationAvailable: item?.isEmailRegistrationAvailable,
    isPhoneRegistrationAvailable: item?.isPhoneRegistrationAvailable,
  };
  const defaultValue = options.find(
    ({ value }) => JSON.stringify(value) === JSON.stringify(validateCountry)
  );
  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          flag: item?.flag || "",
          name: item?.name || "",
          code: item?.code || "",
          ISOCode: item?.ISOCode || "",
          Validate: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputUpload
                  label="Country Flag"
                  placeholder="Select your image"
                  required={true}
                  name="flag"
                  formik={formik}
                  deafultFile={item?.flag}
                />
                <div className="form-row">
                  <InputText
                    label="Country name"
                    placeholder="Enter your chapter name"
                    required={true}
                    name="name"
                    type="text"
                  />
                  <InputText
                    label="Country Code"
                    placeholder="Enter your description"
                    required={true}
                    name="code"
                    type="text"
                  />
                </div>
                <InputText
                  label="Abbreviations"
                  placeholder="Add your keyword"
                  required={true}
                  name="ISOCode"
                  type="text"
                />
                <InputSelectMultiple
                  label="Verification Type"
                  placeholder="Add your keyword"
                  required={true}
                  name="Validate"
                  mutiple={false}
                  defaultValue={defaultValue || options[0]}
                  options={options}
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} country`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default CountryFormHandler;
