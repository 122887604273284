import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputLibaray from "../../../../components/formInputTypes/inputLibrary/inputLibaray";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { getKeywords } from "../../../../store/slices/keywords";
import {
  createRevisions,
  updateRevision,
} from "../../../../store/slices/revisions";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import InputArrayText from "../../../../components/formInputTypes/inputArrayText/inputArrayText";
import { ImageYupValidation } from "../../../../constant/yupSchemas/imageSchema";

function RevisionFormHandler() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, item, id, type } = useModalFormContext();
  const { keywords } = useSelector((state) => state.keywords);
  const { currentLanguage } = useSelector((state) => state.languages);

  const validate = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    text: Yup.string().required("Text is required"),
    keywordId: Yup.string().required("Keyword is required"),
    answers: Yup.array()
      .of(Yup.string().required("answer is required"))
      .min(1)
      .required("Answers Array is required"),
    audioUrl: Yup.string().required("Audio is required"),
    imageUrl: ImageYupValidation(),
  });

  useEffect(() => {
    if (keywords.length == 0) dispatch(getKeywords({ limit: -1 }));
  }, []);

  const options = keywords?.map((keyword) => {
    return { label: keyword.sentence, value: keyword._id };
  });

  const submitForm = (values) => {
    const { title, text, keywordId, answers, audioUrl, imageUrl } = values;
    const revision = {
      answers,
      title,
      text,
      keywordId,
      audioUrl,
      imageId: imageUrl?._id,
      imageUrl: !imageUrl?._id ? imageUrl : null,
      languageId: currentLanguage?._id,
    };

    setLoading(true);
    dispatch(
      item
        ? updateRevision({ id, revision: convertObjectToFormData(revision) })
        : createRevisions(convertObjectToFormData(revision))
    ).then((res) => {
      setLoading(false);
      if (res.error) showNotif(res.payload.data.message);
      else {
        hidePopup();
        showNotif(`revesion ${type}ed successfully`, "success");
      }
    });
  };

  return (
    <div className=" customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          text: item?.text || "",
          keywordId: item?.keywordId?._id || item?.keywordId || "",
          answers: item?.answers || [""],
          audioUrl: item?.audioUrl || "",
          imageUrl: item?.imageUrl || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <div className="form-row">
                  <InputText
                    label="Title"
                    placeholder="Enter your title"
                    required={true}
                    name="title"
                    type="text"
                  />
                  <InputText
                    label="Text"
                    placeholder="Enter your Text"
                    required={true}
                    name="text"
                    type="text"
                  />
                </div>
                <InputSelect
                  label="Keyword"
                  placeholder="Enter your Keyword"
                  required={true}
                  name="keywordId"
                  options={options}
                />
                <div className="form-row">
                  <InputUpload
                    label="Audio"
                    placeholder="Select your audio"
                    required={true}
                    name="audioUrl"
                    formik={formik}
                    accept=".mp3,audio/*"
                    deafultFile={item?.audioUrl}
                  />
                  <InputLibaray
                    label="Image"
                    placeholder="Select your image"
                    required={true}
                    name="imageUrl"
                    formik={formik}
                    deafultFile={item?.imageUrl}
                  />
                </div>
                <div className="inputContainer--add">
                  <InputArrayText
                    label="Answer"
                    placeholder="Enter your Answer"
                    required={true}
                    name="answers"
                    formik={formik}
                  />
                </div>
              </div>
              <FormButton label={loading ? <Loading /> : `${type} Revision`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default RevisionFormHandler;
