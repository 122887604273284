export const AdminFormData = {
  form: "adminForm",
  header: "create new admin",
};

export const UserFormData = {
  form: "userForm",
  header: "create new user",
};

export const ChapterFormData = {
  form: "chapterForm",
  header: "Add New Chapter",
};

export const LessonFormData = {
  form: "lessonForm",
  header: "Add New Lesson",
};

export const ExerciseFormData = {
  form: "exerciceForm",
  header: "Add New exercise",
};

export const ExerciseTypeFormData = {
  form: "exerciceTypeForm",
  header: "Add New exercise type",
};

export const LevelFormData = {
  form: "levelForm",
  header: "Add New Level",
};

export const QuestionTypeFormData = {
  form: "questionTypeForm",
  header: "Add New Question Type",
};

export const KeywordFormData = {
  form: "keywordForm",
  header: "Add New Keyword",
};

export const RevesionFormData = {
  form: "revisionForm",
  header: "Add New Revesion",
};

export const createNotificationFormData = {
  form: "createNotification",
  header: "push Notification",
};

export const QuestionFormData = {
  form: "questionForm",
  header: "create Question",
};

export const CountryFormData = {
  form: "countryForm",
  header: "create country",
};

export const banUserFormData = {
  form: "banUser",
  header: "ban user",
};

export const banUserPreview = {
  form: "banUserPreview",
  header: "ban user preview",
};

export const ReportFormData = {
  form: "reportForm",
  header: "Create report",
};

export const searchFormData = {
  form: "searchForm",
  header: "Replace ",
};

export const badgeFormData = {
  form: "badgeForm",
  header: "Badges ",
};
export const rolesFormData = {
  form: "rolesForm",
  header: "Roles ",
};

export const foldersLibraryFormData = {
  form: "foldersLibraryForm",
  header: "Folder ",
};

export const ImageFormData = {
  form: "ImageForm",
  header: "Image ",
};

export const languageFormData = {
  form: "languageForm",
  header: "Language",
};
export const duplicateLessonForm = {
  form: "duplicateLessonForm",
  header: "Duplicate Lesson",
};
