import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputText from "../../../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../../../components/formInputTypes/inputUpload/inputUpload";
import Loading from "../../../../../../components/loadin/Loading";
import { ImageYupValidation } from "../../../../../../constant/yupSchemas/imageSchema";
import { useApiErrorContext } from "../../../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../../../context/modalFormContext";
import { convertObjectToFormData } from "../../../../../../heplers/convertObjectToFormData";
import {
  createLanguage,
  updateLanguage,
} from "../../../../../../store/slices/languages";
import { FormButton } from "../../../../../../views/admins/components/form/adminFormHandler";

function LanguageForm() {
  const { hidePopup, item, id, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const validate = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    thumbnail: Yup.string().required("image is required"),
  });

  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  const languageHandler = (values) => {
    setLoading(true);
    dispatch(
      item
        ? updateLanguage({
            id: item?._id,
            language: convertObjectToFormData(values),
          })
        : createLanguage(convertObjectToFormData(values))
    ).then((res) => {
      setLoading(false);
      if (res.error) showNotif(res.payload.data.message);
      else {
        hidePopup();
        showNotif(`language ${type}ed successfully`, "success");
      }
    });
  };

  return (
    <div className=" customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          thumbnail: item?.thumbnail || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          languageHandler(values);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Title"
                  placeholder="Enter your title"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputUpload
                  label="Image"
                  placeholder="Select your audio"
                  required={true}
                  name="thumbnail"
                  formik={formik}
                  deafultFile={item?.thumbnail}
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} Language`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LanguageForm;
