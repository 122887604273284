import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useModalFormContext } from "../../../../context/modalFormContext";
import * as Yup from "yup";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import {
  createExerciceType,
  updateExerciceType,
} from "../../../../store/slices/exerciceTypes";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import Loading from "../../../../components/loadin/Loading";

function ExerciceTypeFormHandler() {
  const { hidePopup, item, id, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    title: Yup.string().required("Lesson Title is required"),
    thumbnail: Yup.string(),
    intro: Yup.string().required("introduction is required"),
  });

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      item
        ? updateExerciceType({
            id,
            exerciceType: convertObjectToFormData(values),
          })
        : createExerciceType(convertObjectToFormData(values))
    ).then((res) => {
      setLoading(false);
      if (res.error) showNotif(res?.payload?.data?.message);
      else {
        hidePopup();
        showNotif(`question type ${type}ed successfully`, "success");
      }
    });
  };

  return (
    <div className="exerciceType customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          thumbnail: item?.thumbnail || "",
          intro: item?.intro || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Exercise type TItle"
                  placeholder="Add your first name"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputText
                  label="Exercise type Introduction"
                  placeholder="Enter your Introduction"
                  required={true}
                  name="intro"
                  type="text"
                />
                <InputUpload
                  label="Image"
                  placeholder="Select your image"
                  required={true}
                  name="thumbnail"
                  formik={formik}
                  deafultFile={item?.thumbnail}
                />
              </div>
              <FormButton
                label={loading ? <Loading /> : `${type}  exercice Type`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ExerciceTypeFormHandler;
