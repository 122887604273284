import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputSelectMultiple from "../../../../components/formInputTypes/inputSelectMultiple/InputSelectMultiple";
import { getKeywords } from "../../../../store/slices/keywords";
import {
  getQuestions,
  getQuestionTypes,
  initialiseQuestion,
  updateQuestion,
} from "../../../../store/slices/questions";
import { useNavigate, useParams } from "react-router-dom";
import { cleanObject } from "../../../../heplers/cleanObject";

function QuestionFormHandler() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { exerciceId } = useParams();
  const { showNotif } = useApiErrorContext();
  const { hidePopup, id, item, type } = useModalFormContext();
  const { keywords } = useSelector((state) => state.keywords);
  const { currentLanguage } = useSelector((state) => state.languages);
  const { questionTypes } = useSelector((state) => state.questions);

  const validate = Yup.object().shape({
    title: Yup.string().required("Title is required"),
    questionType: Yup.string().required("Question type is required"),
    translateAnswer: Yup.string().min(5),
  });

  const submitForm = (values) => {
    const question = cleanObject({
      ...values,
      exerciceId,
    });
    question.keywords = values.keywords?.map((keyword) => keyword.value);
    if (item) {
      dispatch(updateQuestion({ id, question })).then((res) => {
        if (res.error) showNotif(res?.payload?.data?.message, "error");
        else {
          hidePopup();
          showNotif("question updated successfully", "success");
          dispatch(getQuestions({ exerciceId }));
        }
      });
    } else {
      dispatch(initialiseQuestion(question));
      navigate("new");
      hidePopup();
    }
  };

  useEffect(() => {
    if (keywords.length === 0)
      dispatch(getKeywords({ limit: -1, languageId: currentLanguage._id }));
    if (questionTypes.length === 0) dispatch(getQuestionTypes());
  }, []);

  const optionsKeywords = keywords
    .filter((keyword) => keyword.languageId === currentLanguage._id)
    .map((keyword) => {
      return { label: keyword.sentence, value: keyword._id };
    });
  const optionsTypes = questionTypes.map((type) => {
    return { label: type.title, value: type._id };
  });
  const defaultKeywords = keywords
    .map((keyword) => {
      return item?.keywords.includes(keyword._id)
        ? { label: keyword.sentence, value: keyword._id }
        : null;
    })
    .filter((keyword) => !!keyword);

  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          questionType: item?.questionType?._id || "",
          keywords: defaultKeywords || [],
          translateAnswer: item?.translateAnswer || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Question Title"
                  placeholder="Add your title"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputText
                  label="Translate Answer"
                  placeholder="Add your Translation"
                  required={false}
                  name="translateAnswer"
                  type="text"
                />

                <InputSelect
                  label="Question Type"
                  placeholder="Add your Question type"
                  required={true}
                  name="questionType"
                  options={optionsTypes}
                />
                <InputSelectMultiple
                  label="Keyword"
                  placeholder="Add your keyword"
                  name="keywords"
                  options={optionsKeywords}
                  defaultValue={defaultKeywords}
                />
              </div>
              <FormButton label={`${type} Question`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default QuestionFormHandler;
