import { Form, Formik } from "formik";
import React, { useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { getCountries } from "../../../../store/slices/countries";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";
import { createUser, updateUser } from "../../../../store/slices/user";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { findData } from "../../../../heplers/findData";
import { cleanObject } from "../../../../heplers/cleanObject";

const ByEmail = (country, countries) => {
  const {
    isEmailRegistrationAvailable: byEmail,
    isPhoneRegistrationAvailable: bySms,
  } = findData(countries, "_id", country?.value || country) || {};
  return byEmail;
};

function UserFormHandler() {
  const { hidePopup, item, id, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const { countries } = useSelector((state) => state.countries);
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    firstName: Yup.string().required("Fisrt Name Title is required"),
    lastName: Yup.string().required("Last Name Title is required"),
    avatar: Yup.string(),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    passwordConfirm: Yup.string()
      .oneOf([Yup.ref("password"), null], "Passwords must match")
      .when(["password"], {
        is: (Password) => Password,
        then: Yup.string().required("confirm password is required"),
      }),
    learningDuration: Yup.string().required("Leadning Duration is required"),
    countryCode: Yup.string().required("Country is required"),
    phone: Yup.number().when(["countryCode"], {
      is: (Country) => !ByEmail(Country, countries),
      then: Yup.number().min(8).required("Must enter phone number"),
    }),
    email: Yup.string()
      .email("Email is invalid")
      .when(["countryCode"], {
        is: (Country) => ByEmail(Country, countries),
        then: Yup.string().required("Must enter email address"),
      }),
  });

  useEffect(() => {
    if (!countries) dispatch(getCountries());
  }, []);

  const options = countries?.map((country) => {
    return {
      label: country.name,
      value: country._id,
    };
  });

  const submitForm = (values) => {
    const { phone, email } = values;
    const newUser = cleanObject(values);
    if ((item && phone == item?.phone) || email == "") {
      delete newUser.phone;
      delete newUser?.email;
    }
    dispatch(
      item
        ? updateUser({ id, user: convertObjectToFormData(newUser) })
        : createUser(convertObjectToFormData(newUser))
    ).then((res) => {
      if (!res.error) {
        showNotif(`${type}ed user successfully`, "success");
        hidePopup();
      } else showNotif(res.payload.data.message, "error");
    });
  };

  return (
    <div className="createUserForm customForm">
      <Formik
        initialValues={{
          firstName: item?.firstName || "",
          lastName: item?.lastName || "",
          email: item?.email || "",
          avatar: item?.avatar || "",
          password: "",
          passwordConfirm: "",
          learningDuration: item?.learningDuration || "",
          countryCode: item?.countryCode?._id || "",
          phone: item?.phone || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputUpload
                  label="Profile Image"
                  required={false}
                  name="avatar"
                  formik={formik}
                  deafultFile={item?.avatar}
                />
                <div className="form-row">
                  <InputText
                    label="First Name"
                    placeholder="Add your first name"
                    required={true}
                    name="firstName"
                    type="text"
                  />
                  <InputText
                    label="Last Name"
                    placeholder="Add your last name"
                    required={true}
                    name="lastName"
                    type="text"
                  />
                </div>

                <div className="form-row">
                  <InputSelect
                    label="Country"
                    placeholder="Select Your Counry"
                    required={true}
                    name="countryCode"
                    options={options}
                  />
                  <InputText
                    label="Leadning Duration"
                    placeholder="0 Mins"
                    required={true}
                    name="learningDuration"
                    type="text"
                  />
                </div>
                <div className="form-row">
                  <InputText
                    label="Password"
                    placeholder="Add your Password"
                    required={true}
                    name="password"
                    type="password"
                  />
                  <InputText
                    label="Confirm Password"
                    placeholder="Confirm your Password"
                    required={true}
                    name="passwordConfirm"
                    type="password"
                  />
                </div>
                <div className="form-row">
                  {ByEmail(formik.getFieldProps("countryCode"), countries) ? (
                    <InputText
                      label="Email"
                      placeholder="Add your Email"
                      required={true}
                      name="email"
                      type="email"
                    />
                  ) : (
                    <InputText
                      label="Phone Number"
                      placeholder="Add Your Phone Number"
                      required={true}
                      name="phone"
                      type="text"
                    />
                  )}
                </div>
              </div>
              <FormButton label={`${type} user`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default UserFormHandler;
