import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getNotifactions = createAsyncThunk(
  "notification/getNotifactions",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/notifications", params);
      return { data: data.payload.notifications, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createNotifaction = createAsyncThunk(
  "notification/createNotifaction",
  async (notification, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/notifications", notification);
      return data.payload.notification;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteNotifaction = createAsyncThunk(
  "notification/deleteNotifaction",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/notifications", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  notifications: [],
  meta: null,
  error: null,
};

const notificationsSlice = createSlice({
  name: "notification",
  initialState,
  reducers: {},
  extraReducers: {
    [getNotifactions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getNotifactions.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.notifications = data;
      state.meta = meta;
      state.loading = false;
    },
    [getNotifactions.rejected]: (state, { payload }) => {
      state.error = payload;
      state.notifications = [];
      state.state.loading = false;
    },
    [createNotifaction.fulfilled]: (state, { payload }) => {
      state.notifications = [...state.notifications, payload];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteNotifaction.fulfilled]: (state, { payload }) => {
      state.notifications = state.notifications.filter(
        (keyword) => keyword._id !== payload
      );
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
  },
});

export const {} = notificationsSlice.actions;

export default notificationsSlice.reducer;
