import { createAsyncThunk, createSlice, current } from "@reduxjs/toolkit";

import { getData } from "../../services/protectApi";

export const getResources = createAsyncThunk(
  "admin/getResources",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/roles/resources", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const getVerbs = createAsyncThunk(
  "admin/getVerbs",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/roles/verbs", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  resources: null,
  meta: null,
  error: null,
};

const resourcesSlice = createSlice({
  name: "resources",
  initialState,
  reducers: {

  },
  extraReducers: (builder) => {
    builder
      .addCase(getResources.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getResources.fulfilled, (state, { payload }) => {
        state.resources = payload.payload.resources;
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(getResources.rejected, (state, { payload }) => {
        state.error = payload;
        state.resources = [];
        state.loading = false;
      })
      .addCase(getVerbs.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getVerbs.fulfilled, (state, { payload }) => {
        state.verbs = payload.payload.verbs;
        state.meta = payload.meta;
        state.loading = false;
      })
      .addCase(getVerbs.rejected, (state, { payload }) => {
        state.error = payload;
        state.verbs = [];
        state.loading = false;
      });
  },
});



export default resourcesSlice.reducer;
