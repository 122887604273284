import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findData } from "../../heplers/findData";
import { reorderData } from "../../heplers/reorderData";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
  duplicateOne,
} from "../../services/protectApi";

export const getLessons = createAsyncThunk(
  "lesson/getLessons",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, sort: "order" };
      const { data } = await getData("admin/lessons", params);
      return {
        data: data.payload.lessons,
        levelId: params.levelId || "draft",
        courseId: params.courseId,
      };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createLesson = createAsyncThunk(
  "lesson/createLesson",
  async (lessonData, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/lessons", lessonData);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteLesson = createAsyncThunk(
  "lesson/deleteLesson",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/lessons", id);
      return { data: data.payload, id };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateLesson = createAsyncThunk(
  "lesson/updateLesson",
  async ({ id, lesson }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/lessons", id, lesson);
      return data.payload.lesson;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const duplicateLesson = createAsyncThunk(
  "lesson/duplicateLesson",
  async ({ levelId, lessonId }, { rejectWithValue }) => {
    try {
      const { data } = await duplicateOne("admin/lessons", lessonId, {
        levelId,
      });
      return data.payload.lesson;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const reorderLessons = createAsyncThunk(
  "lesson/reorderLessons",
  async (orders, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/lessons/update-order", "", {
        orders,
      });
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  lessons: [],
  copyLessons: [],
  error: null,
  saveChangeError: null,
  saveChangeSuccess: null,
  saveChangeLoading: "",
};

const lessonsSlice = createSlice({
  name: "lesson",
  initialState,
  reducers: {
    resetLessons(state) {
      state.lessons = [];
    },
    setLessons(state, { payload }) {
      state.copyLessons = payload;
    },
    updateLocation(state, { payload }) {
      const id = payload;
      state.copyLessons = state.copyLessons.filter(
        (lesson) => lesson?._id !== id
      );
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(getLessons.pending, (state, { payload }) => {
        state.loading = true;
      })
      .addCase(getLessons.fulfilled, (state, { payload }) => {
        const { data } = payload;
        state.lessons = data;
        state.copyLessons = data;
        state.loading = false;
      })
      .addCase(getLessons.rejected, (state, { payload }) => {
        state.error = payload;
        state.lessons = [];
        state.copyLessons = [];
        state.loading = false;
      })
      .addCase(reorderLessons.fulfilled, (state, { payload }) => {
        state.saveChangeLoading = false;
      })
      .addCase(deleteLesson.fulfilled, (state, { payload }) => {
        const { id } = payload;
        state.copyLessons = state.copyLessons.filter(
          (lesson) => lesson?._id !== id
        );
      });
  },
});

export const { resetLessons, setLessons, updateLocation } =
  lessonsSlice.actions;

export default lessonsSlice.reducer;
