import React from "react";
import deleteIcon from "./../../assets/icons/deleteIcon.png";
import closeIcon from "./../../assets/icons/Close.png";

function Popup({ onClose, action, itemType = "block" }) {
  return (
    <>
      <div className="backscreen" onClick={onClose}></div>
      <div className="modal">
        <div className="modal-header">
          <span>
            <img src={deleteIcon} alt="" />
          </span>
          <span onClick={onClose}>
            <img src={closeIcon} alt="" />
          </span>
        </div>
        <div className="modal-content">
          <p>Delete This {itemType}</p>
          <p>
            Are you sure you want to delete this {itemType}? This action cannot
            be undone.
          </p>
        </div>
        <div className="modal-buttons">
          <button onClick={onClose}>Cancel</button>
          <button onClick={action}>Delete</button>
        </div>
      </div>
    </>
  );
}

export default Popup;
