import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { cleanObject } from "../../../../heplers/cleanObject";
import { createBadge, updateBadge } from "../../../../store/slices/badges";
import * as Yup from "yup";
import Loading from "../../../../components/loadin/Loading";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";

function BadgeForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, id, item, type } = useModalFormContext();

  const validate = Yup.object().shape({
    name: Yup.string().required(" Title is required"),
    imageUrl: Yup.string().required("Image is required"),
    minScore: Yup.string().required("minScore is required"),
    order: Yup.number().required("order is required"),
  });

  const submitForm = (values) => {
    const badge = convertObjectToFormData(cleanObject(values));
    setLoading(true);
    dispatch(item ? updateBadge({ id, badge }) : createBadge(badge)).then(
      (res) => {
        if (!res.error) {
          hidePopup();
          showNotif(`badge ${type}ed successfully`, "success");
        } else showNotif(res.payload.data.message, "error");
        setLoading(false);
      }
    );
  };

  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          name: item?.name || "",
          imageUrl: item?.imageUrl || "",
          minScore: item?.minScore || "",
          order: item?.order || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Title"
                  placeholder="Add your title badge"
                  required={true}
                  name="Title"
                  type="text"
                />
                <InputUpload
                  label="Image"
                  placeholder="Add your image"
                  required={true}
                  name="Image"
                  formik={formik}
                  deafultFile={item?.imageUrl}
                />
                <InputText
                  label="Min Score"
                  placeholder="add you min score must be a number"
                  required={true}
                  name="minScore"
                />
                <InputText
                  label="Order"
                  placeholder="add Order"
                  required={true}
                  name="order"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} badge`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default BadgeForm;
