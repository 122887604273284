import React, { useEffect, useState } from "react";
import { useAbility } from "@casl/react";
import { AbilityContext } from "../../context/abilityContext";
import { usePortal } from "../../hooks/usePortal";
import Popup from "../popup/popup";
import test from "./../../assets/icons/Setting.png";

function More({ actionsList, itemID, setMoreOpen }) {
  const [showPopup, setShowPopup] = useState(false);
  const [deleteAction, setDeleteAction] = useState(() => {});
  const [itemType, setItemType] = useState();
  const Portal = usePortal(document.getElementById("popup"));
  const ability = useAbility(AbilityContext);

  useEffect(() => {
    document.addEventListener("click", (e) => {
      if (e.target.id != "more") setMoreOpen(false);
    });
  }, []);

  useEffect(() => {}, [actionsList]);

  const handleAction = (action) => {
    if (action.label.toLowerCase() === "delete" && showPopup === false) {
      setShowPopup(true);
      setItemType(action.itemType);
      setDeleteAction(() => action.action.bind(null, itemID));
      if (
        action.itemType.toLowerCase() === "level" ||
        action.itemType.toLowerCase() === "course"
      )
        action.action();
    } else {
      action.action(itemID);
    }
  };

  return (
    <div className="more-container" id="more">
      {showPopup ? (
        <Portal>
          <Popup
            itemType={itemType}
            onClose={() => setShowPopup(false)}
            action={() => deleteAction()}
          />
        </Portal>
      ) : null}
      {actionsList?.map((action, i) => {
        return (
          ability.can(action.permissions.verb, action.permissions.resource) && (
            <div
              id="more"
              className="more-container-item"
              key={i}
              onClick={() => handleAction(action)}
            >
              <img id="more" src={test} alt="error" />
              <p id="more">{action.label}</p>
            </div>
          )
        );
      })}
    </div>
  );
}

export default More;
