import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getAdmins = createAsyncThunk(
  "admin/getAdmins",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/admins", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createAdmin = createAsyncThunk(
  "admin/createAdmin",
  async (newAdmin, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/admins", newAdmin);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteAdmin = createAsyncThunk(
  "admin/deleteAdmin",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/admins", id);
      return { id };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateAdmin = createAsyncThunk(
  "admin/updateAdmin",
  async ({ id, admin }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/admins", id, admin);
      return data.payload.admin;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  admins: null,
  meta: null,
  error: null,
};

const adminSlice = createSlice({
  name: "admin",
  initialState,
  reducers: {},
  extraReducers: {
    [getAdmins.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getAdmins.fulfilled]: (state, { payload }) => {
      state.admins = payload.payload.admins;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getAdmins.rejected]: (state, { payload }) => {
      state.error = payload;
      state.admins = [];
      state.loading = false;
    },
    [createAdmin.fulfilled]: (state, { payload }) => {
      state.admins = [...state.admins, payload.admin];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteAdmin.fulfilled]: (state, { payload }) => {
      state.admins = state.admins.filter((admin) => admin._id !== payload.id);
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
    [updateAdmin.fulfilled]: (state, { payload }) => {
      state.admins = state.admins.map((admin) =>
        admin._id === payload._id ? { ...payload } : { ...admin }
      );
    },
  },
});

export default adminSlice.reducer;
