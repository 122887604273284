import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getExerciceTypes = createAsyncThunk(
  "exercice-type/getExerciceTypes",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/exercice-type", params);
      return { data: data?.payload?.exerciceTypes, meta: data?.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createExerciceType = createAsyncThunk(
  "exercice-type/createExerciceType",
  async (exerciceType, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/exercice-type", exerciceType);
      return data.payload.exerciceType;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteExerciceType = createAsyncThunk(
  "exercice-type/deleteExerciceType",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/exercice-type", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateExerciceType = createAsyncThunk(
  "exercice-type/updateExerciceType",
  async ({ id, exerciceType }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/exercice-type", id, exerciceType);
      return data.payload.exerciceType;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  exercicesType: [],
  error: null,
};

const exerciceTypeSlice = createSlice({
  name: "exerciceType",
  initialState,
  reducers: {},
  extraReducers: {
    //get exercices
    [getExerciceTypes.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getExerciceTypes.fulfilled]: (state, { payload }) => {
      state.exercicesType = payload.data;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getExerciceTypes.rejected]: (state, { payload }) => {
      state.error = payload;
      state.exercicesType = [];
      state.loading = false;
    },
    [createExerciceType.fulfilled]: (state, { payload }) => {
      state.exercicesType = [...state.exercicesType, payload];
    },
    [deleteExerciceType.fulfilled]: (state, { payload }) => {
      state.exercicesType = state.exercicesType.filter(
        (exerciceType) => exerciceType._id !== payload
      );
    },
    [updateExerciceType.fulfilled]: (state, { payload }) => {
      state.exercicesType = state.exercicesType.map((exerciceType) =>
        exerciceType._id == payload._id ? { ...payload } : { ...exerciceType }
      );
    },
  },
});

export const {} = exerciceTypeSlice.actions;

export default exerciceTypeSlice.reducer;
