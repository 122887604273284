import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findData } from "../../heplers/findData";
import { createOne, getData, updateOne } from "../../services/protectApi";

export const getLanguages = createAsyncThunk(
  "languages/getLanguages",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await getData("admin/languages", { sort: "createdAt" });
      return data.payload.languages;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createLanguage = createAsyncThunk(
  "languages/createLanguages",
  async (language, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/languages", language);
      return data.payload.language;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateLanguage = createAsyncThunk(
  "languages/updateLanguage",
  async ({ id, language }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/languages", id, language);
      return data.payload.language;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  languages: null,
  error: null,
  currentLanguage: localStorage.getItem("activeLanguage") || "",
};

const languagesSlice = createSlice({
  name: "languages",
  initialState,
  reducers: {
    setCurrentLanguage(state, { payload }) {
      const newLanguage = state.languages.find(
        (language) => language._id == payload
      );
      state.currentLanguage = newLanguage;
    },
  },
  extraReducers: {
    [getLanguages.fulfilled]: (state, { payload }) => {
      state.languages = payload;
      state.currentLanguage =
        findData(state.languages, "_id", state.currentLanguage) || payload[0];
    },
    [getLanguages.rejected]: (state, { payload }) => {
      state.languages = [];
      state.error = payload;
    },
    [updateLanguage.fulfilled]: (state, { payload }) => {
      state.languages = state.languages.map((language) =>
        language?._id === payload?._id ? payload : language
      );
      state.currentLanguage = payload;
    },
  },
});

export const languagesActions = languagesSlice.actions;

export default languagesSlice.reducer;
