import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import * as Yup from "yup";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { createReport, updateReport } from "../../../../store/slices/reports";
import { FormButton } from "../../../admins/components/form/adminFormHandler";

function ReportFormHandler() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, type, id, item } = useModalFormContext();

  const validate = Yup.object().shape({
    label: Yup.string().required("Name is required"),
  });

  const submitForm = (values) => {
    dispatch(
      item ? updateReport({ id, report: values }) : createReport(values)
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`report ${type}ed successfully`, "success");
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          label: item?.label || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="report label"
                  placeholder="Enter your chapter label"
                  required={true}
                  name="label"
                  type="text"
                />
              </div>
              <FormButton label={loading ? <Loading /> : type + " report"} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ReportFormHandler;
