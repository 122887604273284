import { Suspense, lazy, useEffect, Fragment } from "react";
import {
  Route,
  Routes,
  useLocation,
  useNavigate,
  Navigate,
} from "react-router-dom";
import { Spinner } from "./components/loadingView/LoadingView";
import { PATH } from "./constant/routes/paths";
import { useAuth } from "./context/authContext";
import MainLayout from "./layouts/mainLayout/mainLayout";
import { Can } from "./context/abilityContext";
import { RESOURCES, VERBS } from "./constant/permission";

export const RenderRoutes = ({ routes = [] }) => {
  const { user, isAuthenticated } = useAuth();
  const navigate = useNavigate();
  const { pathname } = useLocation();

  if (process.env.REACT_APP_BASE_NODE_ENV !== "development") {
    console.log(process.env.REACT_APP_BASE_NODE_ENV);
    console.log = () => {};
  }

  useEffect(() => {
    const url = pathname;
    const publicPaths = [PATH.LOGIN];
    const path = url.split("?")[0];
    if (!user && !isAuthenticated && !publicPaths.includes(path))
      navigate(PATH.LOGIN);
    else if ((isAuthenticated && publicPaths.includes(path)) || url === "/")
      navigate(PATH.DASHBOARD);
  }, [user, isAuthenticated, pathname]);

  return (
    <Suspense
      fallback={
        <div className="spinner">
          <Spinner />
        </div>
      }
    >
      <Routes>
        {routes.map((route, i) => {
          const Layout = route.layout ? route.layout : Fragment;
          const Component = route.component;
          if (route.accessPermissions === undefined) {
            return (
              <Route
                key={i}
                path={route.path}
                index={route.index ? route.index : false}
                element={
                  <Layout>
                    <Component />
                  </Layout>
                }
              >
                {route.routes?.length > 0 &&
                  route.routes.map((subRoute, j) => {
                    const Component = subRoute.component;
                    return (
                      <Route
                        path={subRoute.path}
                        index
                        element={<Component />}
                        key={j}
                      />
                    );
                  })}
              </Route>
            );
          } else {
            return (
              <Route
                key={i}
                path={route.path}
                index={route.index ? route.index : false}
                element={
                  <Layout>
                    <Can
                      I={route.accessPermissions.verb}
                      a={route.accessPermissions.resource}
                    >
                      <Component />
                    </Can>
                  </Layout>
                }
              >
                {route.routes?.length > 0 &&
                  route.routes.map((subRoute, j) => {
                    const Component = subRoute.component;
                    return (
                      <Route
                        path={subRoute.path}
                        index
                        element={<Component />}
                        key={j}
                      />
                    );
                  })}
              </Route>
            );
          }
        })}
      </Routes>
    </Suspense>
  );
};

const routes = [
  {
    path: PATH.DASHBOARD,
    layout: MainLayout,
    component: lazy(() => import("./views/home/Home")),
  },
  {
    index: true,
    layout: MainLayout,
    path: PATH.ADMINS,
    component: lazy(() => import("./views/admins/Admins")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.ADMINS },
  },
  {
    path: PATH.USERS,
    layout: MainLayout,
    component: lazy(() => import("./views/users/Users")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.USERS },
  },
  {
    path: PATH.BADGES,
    layout: MainLayout,
    component: lazy(() => import("./views/badges/Badges")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.BADGES },
  },
  {
    path: PATH.ROLES,
    layout: MainLayout,
    component: lazy(() => import("./views/roles/Roles")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.ROLES },
  },
  {
    path: PATH.CREATEROLE,
    layout: MainLayout,
    component: lazy(() => import("./views/roles/components/form/FormWrapper")),
    accessPermissions: { verb: VERBS.CREATE, resource: RESOURCES.ROLES },
  },
  {
    path: PATH.UPDATEROLE,
    layout: MainLayout,
    component: lazy(() => import("./views/roles/components/form/FormWrapper")),
    accessPermissions: { verb: VERBS.UPDATE, resource: RESOURCES.ROLES },
  },
  {
    path: PATH.CHAPTERS,
    layout: MainLayout,
    component: lazy(() => import("./views/chapters/Chapters")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.COURSES },
  },
  {
    path: PATH.LEVELS,
    layout: MainLayout,
    component: lazy(() => import("./views/lessonLevel/LessonLevel")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.LEVELS },
  },
  {
    path: PATH.LESSONS,
    layout: MainLayout,
    component: lazy(() => import("./views/lessons/Lessons")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.LESSONS },
  },
  {
    path: PATH.EXERCICES,
    layout: MainLayout,
    component: lazy(() => import("./views/exercices/Exercices")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.EXERCISES },
  },
  {
    path: PATH.QUESTIONS,
    layout: MainLayout,
    component: lazy(() => import("./views/questions/Question")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.QUESTIONS },
  },
  {
    path: PATH.CREATEQUESTION,
    layout: MainLayout,
    component: lazy(() =>
      import("./views/questions/createQuestion/CreateQuestion")
    ),
    accessPermissions: { verb: VERBS.CREATE, resource: RESOURCES.QUESTIONS },
  },
  {
    path: PATH.QUESTION,
    layout: MainLayout,
    component: lazy(() =>
      import("./views/questions/createQuestion/CreateQuestion")
    ),
    accessPermissions: { verb: VERBS.CREATE, resource: RESOURCES.QUESTIONS },
  },
  {
    path: PATH.EXERCICETYPE,
    layout: MainLayout,
    component: lazy(() => import("./views/exerciceType/ExerciceType")),
    accessPermissions: {
      verb: VERBS.READ,
      resource: RESOURCES.EXERCISES_TYPES,
    },
  },
  {
    path: PATH.QUESTIONSTYPE,
    layout: MainLayout,
    component: lazy(() =>
      import("./views/questions/questionType/QuestionType")
    ),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.QUESTION_TYPES },
  },
  {
    path: PATH.COUNTRIES,
    layout: MainLayout,
    component: lazy(() => import("./views/countries/Countries")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.COUNTRIES },
  },
  {
    path: PATH.KEYWORDS,
    layout: MainLayout,
    component: lazy(() => import("./views/keywords/KeyWords")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.KEYWORDS },
  },
  {
    path: PATH.REPORT,
    layout: MainLayout,
    component: lazy(() => import("./views/reports/Report")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.REPORTS },
  },
  {
    path: PATH.NOTIFICATIONS,
    layout: MainLayout,
    component: lazy(() => import("./views/notifications/Notifications")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.NOTIFICATION },
  },
  {
    path: PATH.REVISION,
    layout: MainLayout,
    component: lazy(() => import("./views/revision/Revision")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.REVISIONS },
  },
  {
    path: PATH.LIBRARY,
    layout: MainLayout,
    component: lazy(() => import("./views/library/Library")),
    accessPermissions: {
      verb: VERBS.READ,
      resource: RESOURCES.IMAGES_CATEGORIES,
    },
  },
  {
    path: PATH.IMAGES,
    layout: MainLayout,
    component: lazy(() => import("./views/images/Images")),
    accessPermissions: { verb: VERBS.READ, resource: RESOURCES.IMAGES },
  },
  {
    path: PATH.LOGIN,
    component: lazy(() => import("./views/login/Login")),
  },
  {
    path: PATH.ERROR,
    component: lazy(() => import("./views/notFound/notFound")),
  },
];

export default routes;
