export const reorderData = (source, destination, arr) => {
  const sourceOrder = source?.index;
  const targetOrder = destination?.index;
  const newArray = arr?.map((data, i) => {
    if (data?.order == sourceOrder) {
      return { ...data, order: targetOrder };
    }
    if (data.order == targetOrder) {
      return { ...data, order: sourceOrder };
    }
    if (data?.order != targetOrder && data?.order != sourceOrder) {
      return { ...data };
    }
  });

  return newArray?.sort((a, b) => a?.order - b?.order);
};
