import React from "react";
import { useModalFormContext } from "../../../../context/modalFormContext";

function BanUserPreview() {
  const { item } = useModalFormContext();
  const { reason, banEndsAt, screenshot } = item || {};
  return (
    <div className="inputContainer">
      <div className="row-us">
        <p className="label">Reason</p>
        <p className="content">{reason || ""}</p>
      </div>
      <div className="row-us">
        <p className="label">Screen shot</p>
        <p className="content">
          {screenshot ? (
            <img src={screenshot} alt="error" />
          ) : (
            "screenshot not found"
          )}
        </p>
      </div>
      <div className="row-us">
        <p className="label">Ban End at date</p>
        <p className="content">{new Date(banEndsAt)?.toLocaleDateString()} </p>
      </div>
    </div>
  );
}

export default BanUserPreview;
