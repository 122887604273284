import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { getData } from "../../services/protectApi";

export const getQuestionBlockType = createAsyncThunk(
  "questionBlockType/getQuestionBlockType",
  async (params = {}, { getState, rejectWithValue }) => {
    params = { ...params, sort: { code: -1 } };
    try {
      const { data } = await getData("admin/question-block-types", params);
      return data.payload.questionBlockTypes;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  questionBlockType: null,
  error: null,
  loading: "",
};

const questionBlockTypeSlice = createSlice({
  name: "questionBlockType",
  initialState,
  reducers: {},
  extraReducers: {
    [getQuestionBlockType.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getQuestionBlockType.fulfilled]: (state, { payload }) => {
      state.loading = false;
      state.questionBlockType = payload;
    },
    [getQuestionBlockType.rejected]: (state, { payload }) => {
      state.loading = false;
      state.questionBlockType = null;
      state.error = payload;
    },
  },
});

export const questionBlockTypeActions = questionBlockTypeSlice.actions;

export default questionBlockTypeSlice.reducer;
