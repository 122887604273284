import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { getImages, resetImages } from "../../../../store/slices/images";
import LoadingView from "../../../loadingView/LoadingView";
import Search from "../../../search/Search";
import leftIcon from "./../../../../assets/icons/left-long-solid.svg";
import moreIcon from "./../../../../assets/icons/More.png";
import checkIcon from "./../../../../assets/icons/check.svg";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import More from "../../../more/More";
import Paggination from "../../../paggination/Paggination";
import DataEmpty from "../../../dataEmpty/DataEmpty";
import { closeLibrary } from "../../../../store/slices/library";

export const ImageItem = ({
  imageChecked,
  image,
  setImageChecked = () => {},
  actions,
}) => {
  const [moreOpen, setMoreOpen] = useState(false);
  return (
    <div
      className="library__content-library-folders-content-item library__content-library-folders-content-photo-item "
      id={
        (imageChecked && imageChecked?._id == image?._id) || actions
          ? "disfilter"
          : " "
      }
      style={{
        backgroundImage: `linear-gradient(179.8deg, rgba(0, 0, 0, 0) 23.98%, rgba(0, 0, 0, 0.8) 99.82%) ,url("${process.env.REACT_APP_BASE_API_URL?.replace(
          "/api/v2",
          ""
        )}/${image?.path}") `,
      }}
      onClick={() => setImageChecked(image)}
    >
      {actions && (
        <span id="more" onClick={() => setMoreOpen(!moreOpen)}>
          <img src={moreIcon} id="more" />
        </span>
      )}
      {moreOpen && (
        <More
          actionsList={actions}
          itemID={image._id}
          setMoreOpen={setMoreOpen}
        />
      )}
      <p className="library__content-library-folders-content-photo-item--name">
        {image.name}
      </p>
      <p className="library__content-library-folders-content-photo-item--date">
        Added on {new Date(image.createdAt)?.toLocaleDateString()}
      </p>
      {imageChecked && imageChecked?._id == image?._id && (
        <div className="library__content-library-folders-content-photo-item--checked">
          <img src={checkIcon} />
        </div>
      )}
    </div>
  );
};

function ImageSection({ folder, setFolderId, onUpload }) {
  const dispatch = useDispatch();
  const [imageChecked, setImageChecked] = useState(null);
  const [imageSerach, setImageSerach] = useState(null);
  const [limit, setLimit] = useState(25);
  const { showNotif } = useApiErrorContext();
  const folderID = folder?._id;
  const { images, loading, meta } = useSelector((state) => state.images);
  useEffect(() => {
    dispatch(resetImages());
    dispatch(
      getImages({
        categoryID: folderID,
        params: { search: imageSerach, limit },
      })
    );
    setImageChecked(null);
  }, [imageSerach, limit]);

  const handleChange = (e, p) =>
    dispatch(
      getImages({
        categoryID: folderID,
        params: { page: p, limit, search: imageSerach },
      })
    );

  const uploadImage = () => {
    if (!imageChecked) {
      showNotif("you have to check an image ", "error");
      return;
    }
    onUpload(imageChecked);
  };
  return (
    <div className="library__content-library">
      <div
        className="library__content-library-back"
        onClick={() => {
          setFolderId(null);
        }}
      >
        <span>&larr; </span>Back To Folder List
      </div>
      <div className="library__content-library-search">
        <p className="">{folder?.name}</p>
        <Search
          placeholder="Search for a category or image"
          onChange={(v) => setImageSerach(v)}
        />
      </div>
      <div className="library__content-library-folders">
        <LoadingView loading={loading}>
          <div className="library__content-library-folders-content">
            {images?.length > 0 ? (
              images.map((image) => (
                <ImageItem
                  imageChecked={imageChecked}
                  image={image}
                  setImageChecked={setImageChecked}
                  key={image?._id}
                />
              ))
            ) : (
              <DataEmpty />
            )}
          </div>
        </LoadingView>
      </div>
      <div className="library__content-library-buttons">
        <Paggination
          onChange={handleChange}
          count={meta?.totalPages}
          setLimit={setLimit}
        />
        <div className="library__content-library-buttons-content">
          <div
            className="library__content-library-buttons-content-upload"
            onClick={uploadImage}
          >
            Upload
          </div>
        </div>
      </div>
    </div>
  );
}

export default ImageSection;
