import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import { languageFormData } from "../../../../../../constant/ModalFormTypes";
import { useModalFormContext } from "../../../../../../context/modalFormContext";
import {
  getLanguages,
  languagesActions,
} from "../../../../../../store/slices/languages";
import addIcon from "./../../../../../../assets/icons/fi_plus-circle.png";
import angleDown from "./../../../../../../assets/icons/fi_chevron-down.png";
import settingIcon from "./../../../../../../assets/icons/fi_settings.png";
import { PATH } from "../../../../../../constant/routes/paths";

function Languages() {
  const [languageOpen, setLanguageOpen] = useState(false);
  const { languages, currentLanguage } = useSelector(
    (state) => state.languages
  );

  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { showPopup } = useModalFormContext();

  useEffect(() => {
    dispatch(getLanguages());
  }, []);

  const otherCounrys = languages?.filter(
    (country) => country._id !== currentLanguage._id
  );
  const setCurrentLanguage = (id) => {
    dispatch(languagesActions.setCurrentLanguage(id));
    localStorage.setItem("activeLanguage", id);
    setLanguageOpen(false);
    navigate(PATH.CHAPTERS);
  };

  return (
    <div
      className="sidebar__content-language"
      onBlur={() => setLanguageOpen(false)}
      tabIndex="0"
    >
      {languageOpen && (
        <>
          <div className="sidebar__content-language-add">
            <div className="sidebar__content-language-icon">
              <img src={addIcon} alt="error" />
            </div>
            <div
              className="country-name"
              onClick={() => showPopup(languageFormData)}
            >
              <p className="">Add new Language</p>
            </div>
          </div>
          <div className="sidebar__content-language-setting">
            <div className="sidebar__content-language-icon">
              <img src={settingIcon} alt="error" />
            </div>
            <div
              className="country-name"
              onClick={() => showPopup(languageFormData, "", currentLanguage)}
            >
              <p className="">Manage Language</p>
            </div>
          </div>
          <div className="sidebar__content-language-country-content">
            {otherCounrys?.map((country) => (
              <div
                className="sidebar__content-language-country"
                key={country._id}
                onClick={() => setCurrentLanguage(country._id)}
              >
                <div className="country-name">
                  <p className="arText">{country?.title}</p>
                </div>
                <div className="country-flag">
                  <img src={country?.thumbnail} alt="error" />
                </div>
              </div>
            ))}
          </div>
        </>
      )}
      <div
        className="sidebar__content-language-default"
        onClick={() => setLanguageOpen(!languageOpen)}
      >
        <div className="sidebar__content-language-icon">
          <img src={angleDown} alt="error" />
        </div>
        <div className="sidebar__content-language-country">
          <div className="country-name">
            <p className="arText">{currentLanguage?.title}</p>
          </div>
          <div className="country-flag">
            <img src={currentLanguage?.thumbnail} alt="error" />
          </div>
        </div>
      </div>
    </div>
  );
}

export default Languages;
