import { Form, Formik } from "formik";
import { useState } from "react";
import { useDispatch } from "react-redux";
import InputText from "../../../../../components/formInputTypes/inputText/InputText";
import Loading from "../../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../../context/modalFormContext";
import {
  createQuestionType,
  updateQuestionType,
} from "../../../../../store/slices/questions";
import { FormButton } from "../../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";

function QuestionTypeFormHandler() {
  const { hidePopup, type, id, item } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    title: Yup.string().trim().min(1).required("Title is required"),
    color: Yup.string()
      .min(7)
      .max(7)
      .required("color is required")
      .test("Check prefix", function () {
        const hex = this.parent["color"];
        const reg = /^#([0-9A-F]{3}){1,2}$/i;
        return reg.test(hex);
      }),
  });

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      item
        ? updateQuestionType({ id, questionType: values })
        : createQuestionType(values)
    ).then((res) => {
      setLoading(false);
      if (res.error) showNotif(res?.payload?.data?.message);
      else {
        showNotif(`question type ${type}ed successfully`, "success");
        hidePopup();
      }
    });
  };

  return (
    <div className="exerciceType customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          color: item?.color || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="question type TItle"
                  placeholder="Add your title"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputText
                  label="question type Introduction"
                  placeholder="Enter your color"
                  required={true}
                  name="color"
                  type="text"
                />
              </div>
              <FormButton
                label={loading ? <Loading /> : `${type} exercice Type`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default QuestionTypeFormHandler;
