import { Form, Formik } from "formik";
import React from "react";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";
import { banUser } from "../../../../store/slices/user";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";

function BanUserForm() {
  const { id } = useModalFormContext();
  const { hidePopup } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    reason: Yup.string().required("reason is required"),
    banEndsAt: Yup.date().min(new Date()).required("date is required"),
    screenshot: Yup.string(),
  });

  const submitForm = (values) => {
    dispatch(
      banUser({
        id,
        banData: convertObjectToFormData(values),
      })
    ).then((res) => {
      if (!res.error) {
        hidePopup();
        showNotif("user banned successfully", "success");
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  return (
    <div className="createUserForm customForm">
      <Formik
        initialValues={{
          reason: "",
          banEndsAt: "",
          screenshot: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Reason"
                  placeholder="Add your first name"
                  required={true}
                  name="reason"
                  type="text"
                />
                <InputUpload
                  label="Screen shot"
                  required={false}
                  name="screenshot"
                  formik={formik}
                />
                <InputText
                  label="Date"
                  placeholder="select"
                  required={false}
                  name="banEndsAt"
                  type="date"
                />
              </div>
              <FormButton label="Ban user" />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default BanUserForm;
