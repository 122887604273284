import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import {
  createKeyword,
  updateKeyword,
} from "../../../../store/slices/keywords";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";
import InputLibaray from "../../../../components/formInputTypes/inputLibrary/inputLibaray";
import { ImageYupValidation } from "../../../../constant/yupSchemas/imageSchema";
import { useSelector } from "react-redux";

function KeywordFormHandler() {
  const dispatch = useDispatch();
  const { hidePopup, type, id, item } = useModalFormContext();
  const { currentLanguage } = useSelector((state) => state.languages);
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");

  const validate = Yup.object().shape({
    sentence: Yup.string().required("Sentence is required"),
    example: Yup.string().required("Example is required"),
    translate: Yup.string().required("Translate is required"),
    audioUrl: Yup.string().required("Audio is required"),
    imageUrl: ImageYupValidation(),
  });

  const submitForm = (values) => {
    const { sentence, example, translate, audioUrl, imageUrl } = values;
    const keyword = {
      sentence,
      example,
      translate,
      audioUrl,
      imageId: imageUrl?._id,
      languageId: currentLanguage?._id,
    };

    setLoading(true);
    dispatch(
      item
        ? updateKeyword({
            id,
            keyword: convertObjectToFormData(keyword),
          })
        : createKeyword(convertObjectToFormData(keyword))
    ).then((res) => {
      setLoading(false);
      if (res?.error) showNotif(res?.payload?.data?.message);
      else {
        showNotif(`keyword ${type}ed successfully`, "success");
        hidePopup();
      }
    });
  };

  return (
    <div className="exerciceType customForm">
      <Formik
        initialValues={{
          sentence: item?.sentence || "",
          example: item?.example || "",
          translate: item?.translate || "",
          audioUrl: item?.audioUrl || "",
          imageUrl: item?.imageUrl || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <div className="form-row">
                  <InputText
                    label="Sentence"
                    placeholder="Enter your Sentence"
                    required={true}
                    name="sentence"
                    type="text"
                  />
                  <InputText
                    label="Example"
                    placeholder="Enter your Example"
                    required={true}
                    name="example"
                    type="text"
                  />
                </div>
                <InputText
                  label="Translate"
                  placeholder="Enter your translate"
                  required={true}
                  name="translate"
                  type="text"
                />
                <div className="form-row">
                  <InputUpload
                    label="Audio"
                    placeholder="Select your audio"
                    required={true}
                    name="audioUrl"
                    formik={formik}
                    accept=".mp3,audio/*"
                    deafultFile={item?.audioUrl}
                  />
                  <InputLibaray
                    label="Image"
                    placeholder="Select your image"
                    required={true}
                    name="imageUrl"
                    formik={formik}
                    deafultFile={item?.imageUrl}
                  />
                </div>
              </div>
              <FormButton label={loading ? <Loading /> : `${type} Keyword`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default KeywordFormHandler;
