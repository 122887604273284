import React, { useEffect, useState } from "react";
import searchIcon from "./../../assets/icons/Search.png";

function Search({ placeholder, onChange }) {
  const searchValue = (e) => {
    const value = e.target.value;
    if (value == null) return;
    const delayDebounceFn = setTimeout(() => {
      onChange(value);
    }, 500);
    return () => clearTimeout(delayDebounceFn);
  };

  return (
    <div className="search">
      <div className="search-input">
        <img src={searchIcon} alt="error" />
        <input type="text" placeholder={placeholder} onChange={searchValue} />
      </div>
    </div>
  );
}

export default Search;
