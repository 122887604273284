import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  getOne,
  updateOne,
} from "../../services/protectApi";

export const getQuestions = createAsyncThunk(
  "question/getQuestions",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, sort: "order" };
      const { data } = await getData("admin/questions", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getQuestion = createAsyncThunk(
  "question/getQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await getOne("admin/questions", id);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateQuestion = createAsyncThunk(
  "question/updateQuestion",
  async ({ id, question }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/questions", id, question);
      return data?.payload?.question;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const reaoderQuestions = createAsyncThunk(
  "question/reaoderQuestions",
  async (orders, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/questions/update-order", "", {
        orders,
      });
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteQuestion = createAsyncThunk(
  "question/deleteQuestion",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/questions", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createQuestion = createAsyncThunk(
  "question/createQuestion",
  async (question, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/questions", question);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createQuestionType = createAsyncThunk(
  "question/createQuestionType",
  async (questionType, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/question-types", questionType);
      return data.payload.questionType;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getQuestionTypes = createAsyncThunk(
  "question/getQuestionTypes",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/question-types", params);
      return { data: data?.payload?.questionTypes, meta: data?.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteQuestionType = createAsyncThunk(
  "question/deleteQuestionType",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/question-types", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateQuestionType = createAsyncThunk(
  "question/updateQuestionType",
  async ({ id, questionType }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne(
        "admin/question-types",
        id,
        questionType
      );
      return data.payload.questionType;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  questions: [],
  questionTypes: [],
  createdQuestion: {},
  question: null,
  meta: null,
  error: null,
};

const questionsSlice = createSlice({
  name: "question",
  initialState,
  reducers: {
    initialiseQuestion(state, { payload }) {
      state.createdQuestion = { ...payload };
    },
    resetQuestion(state) {
      state.question = null;
    },
  },
  extraReducers: {
    [getQuestions.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getQuestions.fulfilled]: (state, { payload }) => {
      state.questions = payload.payload.questions;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getQuestions.rejected]: (state, { payload }) => {
      state.error = payload;
      state.questions = [];
      state.loading = false;
    },

    [updateQuestion.fulfilled]: (state, { payload }) => {
      state.questions = state.questions.map((question) =>
        question?._id == payload?._id ? payload : question
      );
    },
    [deleteQuestion.fulfilled]: (state, { payload }) => {
      state.questions = state.questions.filter(
        (question) => question._id !== payload
      );
    },

    [getQuestionTypes.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getQuestionTypes.fulfilled]: (state, { payload }) => {
      state.questionTypes = payload.data;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getQuestionTypes.rejected]: (state, { payload }) => {
      state.loading = false;
      state.questionTypes = [];
    },
    [createQuestionType.fulfilled]: (state, { payload }) => {
      state.questionTypes = [...state.questionTypes, payload];
    },
    [deleteQuestionType.fulfilled]: (state, { payload }) => {
      state.questionTypes = state.questionTypes.filter(
        (questionType) => questionType._id !== payload
      );
    },
    [updateQuestionType.fulfilled]: (state, { payload }) => {
      state.questionTypes = state.questionTypes.map((questionType) =>
        questionType._id == payload._id ? { ...payload } : { ...questionType }
      );
    },
    [getQuestion.fulfilled]: (state, { payload }) => {
      state.question = payload.question;
    },
  },
});

export const { initialiseQuestion, resetQuestion } = questionsSlice.actions;

export default questionsSlice.reducer;
