import { Pagination } from "@mui/material";
import React, { useEffect } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import LoadingView from "../../components/loadingView/LoadingView";
import MuiTable from "../../components/table/Table";
import { searchListColumns } from "../../constant/ColumnsTable";
import { searchFormData } from "../../constant/ModalFormTypes";
import { RESOURCES, VERBS } from "../../constant/permission";
import { useModalFormContext } from "../../context/modalFormContext";
import { findData } from "../../heplers/findData";
import { getSearch } from "../../store/slices/globalSearch";
import { questionBlocksFormActions } from "../../store/slices/questionBlocksForm";
import { resetQuestion } from "../../store/slices/questions";
import Menu from "../components/menu/Menu";

function GlobalSearch({ value, setValue }) {
  const {
    id,
    levelId,
    idLesson,
    exerciceId: exerciseId,
    questionId,
  } = useParams();
  let query = {
    levelId,
    lessonId: idLesson,
    exerciseId,
    questionId,
    courseId: id,
  };
  const { showPopup } = useModalFormContext();
  const { currentLanguage } = useSelector((state) => state.languages);
  const { searchData, meta, loading } = useSelector(
    (state) => state.globalSearch
  );

  const getSearchData = (params = {}) =>
    dispatch(
      getSearch({
        ...query,
        ...params,
        word: value,
        languageId: currentLanguage?._id,
      })
    );

  const handleChange = (e, p) => {
    getSearchData({ page: p });
  };

  const dispatch = useDispatch();
  useEffect(() => {
    getSearchData();
  }, [value]);

  const navigate = useNavigate();
  const serachAction = [
    {
      code: 1,
      icon: "",
      label: "Open",
      permission: { verb: VERBS.READ, resource: RESOURCES.LEVELS },
      action: (id) => {
        const { levelId, courseId, lessonId, questionId, exerciseId } =
          findData(searchData, "_id", id);
        navigate(
          `/chapters/${courseId}/levels/${levelId}/lessons/${lessonId}/exercices/${exerciseId}/questions/${questionId}`
        );
        setValue("");
        dispatch(questionBlocksFormActions.resetQuestionBlockForm());
        dispatch(resetQuestion());
      },
    },
    {
      icon: "",
      label: "replace",
      permissions: { verb: VERBS.UPDATE, resource: RESOURCES.LEVELS },
      action: (id) => {
        const { questionId } = findData(searchData, "_id", id);
        showPopup(searchFormData, [questionId], value);
      },
    },
  ];
  return (
    <div className="GlobalSearch data-container">
      <Menu
        title="Search"
        description={value}
        number={meta?.totalDocs}
        labelButton="Reaplace All"
        permissions={{ verb: VERBS.READ, resource: RESOURCES.LEVELS }}
        onClick={() =>
          showPopup(
            searchFormData,
            searchData.map(({ questionId }) => questionId),
            value
          )
        }
      />
      <div className="GlobalSearch-content data-container-content">
        <LoadingView loading={false}>
          <div className="data-container-content-table">
            <MuiTable
              columns={searchListColumns}
              data={searchData}
              tableActions={serachAction}
              handleDragEnd={() => {}}
            />
          </div>
        </LoadingView>
        <div className="paggination">
          <Pagination
            count={meta?.totalPages}
            color="primary"
            onChange={handleChange}
          ></Pagination>
        </div>
      </div>
    </div>
  );
}

export default GlobalSearch;
