import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getSearch = createAsyncThunk(
  "search/getSearch",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, limit: 10 };
      const { data } = await getData("admin/question-blocks/search", params);
      return { data: data?.payload.data, meta: data?.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateSearch = createAsyncThunk(
  "search/updateSearch",
  async (replacedData, { rejectWithValue }) => {
    try {
      const { data } = await updateOne(
        "admin/question-blocks/replace",
        "",
        replacedData
      );

      return { data };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  searchData: null,
  meta: null,
  error: null,
};

const globalSearchSlice = createSlice({
  name: "globalSearch",
  initialState,
  reducers: {},
  extraReducers: {
    [getSearch.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getSearch.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.searchData = data;
      state.meta = meta;
      state.loading = false;
    },
    [getSearch.rejected]: (state, { payload }) => {
      state.error = payload;
      state.loading = false;
    },
  },
});

export default globalSearchSlice.reducer;
