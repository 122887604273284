import React from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { Checkbox } from "@mui/material";
import { VERBS } from "../../../constant/permission";
import { addPermission } from "../../../store/slices/roles";

const getPermissionByResource = (resource, array) => {
  const permission = array.find(
    (permission) => permission.resource === resource
  );
  return permission;
};

const TableCheckbox = ({ data, column }) => {
  const { verbs } = useSelector((state) => state.resources);
  const { permissions } = useSelector((state) => state.roles);
  const dispatch = useDispatch();

  const updatePermission = () => {
    const permission = getPermissionByResource(data.resources, permissions) || {
      resource: data.resources,
      verbs: [],
    };

    let permissionCopy;
    if (column.value === VERBS.ALL) {
      if (permission.verbs.includes(VERBS.ALL)) {
        permissionCopy = {
          ...permission,
          verbs: [],
        };
      } else {
        permissionCopy = {
          ...permission,
          verbs,
        };
      }
    } else {
      const indexVerb = permission.verbs.findIndex(
        (verb) => verb === column.value
      );
      if (indexVerb >= 0) {
        permissionCopy = {
          ...permission,
          verbs: permission.verbs.filter((_, index) => index !== indexVerb),
        };
      } else {
        permissionCopy = {
          ...permission,
          verbs: [...new Set([...permission.verbs, column.value])],
        };
      }
      if (permissionCopy.verbs.includes(VERBS.ALL)) {
        permissionCopy.verbs = permissionCopy.verbs.filter(
          (verb) => verb !== VERBS.ALL
        );
      }
    }
    dispatch(addPermission({ permission: permissionCopy }));
  };

  return (
    <Checkbox
      onChange={updatePermission}
      style={{ marginRight: 8 }}
      checked={
        getPermissionByResource(data.resources, permissions)?.verbs.includes(
          column.value
        ) ||
        (column.value === VERBS.ALL &&
          getPermissionByResource(data.resources, permissions)?.verbs.length ===
            Object.values(VERBS).length - 1)
      }
    />
  );
};

export default TableCheckbox;
