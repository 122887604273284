import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { useState } from "react";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import Loading from "../../../../components/loadin/Loading";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import { createImage, updateImage } from "../../../../store/slices/images";
import { useParams } from "react-router-dom";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { getImagePath } from "../../../../heplers/getImagePath";

function ImageForm() {
  const dispatch = useDispatch();
  const { folderID } = useParams();
  const [loading, setLoading] = useState("");
  const { hidePopup, id, item, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();

  const validate = Yup.object().shape({
    name: Yup.string().required("folder Name is required"),
    img: Yup.mixed()
      .required("image is requied")
      .test(1000, "File Size is too large", (value) => {
        console.log({ value });
        return value?.size ? value.size <= 2000000 : true;
      }),
  });

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      item
        ? updateImage({ id, image: convertObjectToFormData(values) })
        : createImage({
            categoryID: folderID,
            image: convertObjectToFormData(values),
          })
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`image ${type}ed successfully`, "success");
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  return (
    <div className="lessonForm customForm">
      <Formik
        initialValues={{
          name: item?.name || "",
          img: item?.path || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Image Name"
                  placeholder="Enter your Image name"
                  required={true}
                  name="name"
                  type="text"
                />
                <InputUpload
                  label="Image"
                  placeholder="Select your image"
                  required={true}
                  name="img"
                  formik={formik}
                  deafultFile={getImagePath(item?.path)}
                  accept="image/*"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} image`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ImageForm;
