import { ErrorMessage, useField } from "formik";
import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { openLibrary, resetFile } from "../../../store/slices/library";

function InputLibaray({ formik, required, deafultFile = "", label, ...props }) {
  const [field, meta] = useField(props);
  const { file } = useSelector((state) => state.library);
  const dispatch = useDispatch();

  const openLibraryHandler = () => {
    dispatch(openLibrary());
  };

  useEffect(() => {
    formik.setFieldValue(field.name, "");
    dispatch(resetFile());
  }, []);

  useEffect(() => {
    formik.setFieldValue(field.name, file ? file : deafultFile);
  }, [file]);

  return (
    <div className="inputUpload customInput">
      <label className="label">
        {label}
        {required && <span>*</span>}
      </label>
      <div
        className={`inputUpload-content customcontainer ${
          meta.touched && meta.error ? "is-invalid" : ""
        }`}
      >
        <p className="imageName">
          {!file && !deafultFile ? (
            "Select your Image"
          ) : deafultFile && !file ? (
            <img src={deafultFile} className="image" />
          ) : (
            <img
              src={`${process.env.REACT_APP_BASE_API_URL?.replace(
                "/api/v2",
                ""
              )}/${file?.path}`}
              className="image"
            />
          )}
        </p>
        <label className="buttonUpload" onClick={openLibraryHandler}>
          Browser
        </label>
        <input id={"upload"} {...props} name={field.name} type="file" />
      </div>
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
}

export default InputLibaray;
