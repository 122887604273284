import { Form, Formik } from "formik";
import React, { useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import * as Yup from "yup";
import { createAdmin, updateAdmin } from "../../../../store/slices/admin";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import Loading from "../../../../components/loadin/Loading";
import InputSelectMultiple from "../../../../components/formInputTypes/inputSelectMultiple/InputSelectMultiple";
import { compareArrays } from "../../../../heplers/compareArrays";

export const FormButton = ({ label }) => {
  return (
    <div className="button-form">
      <button className="submitButton" type="submit">
        <p>{label}</p>
      </button>
    </div>
  );
};

function AdminFormHandler() {
  const [submitLoading, setSubmitLoading] = useState("");
  const { roles } = useSelector((state) => state.roles);
  const { hidePopup, id, item, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const dispatch = useDispatch();

  const submitForm = (values) => {
    setSubmitLoading(true);
    const roleIds = values.roles.map((role) => role.value);
    let admin = { fullName: values?.fullName };
    if (values?.email !== item?.email) admin.email = values.email;
    if (!compareArrays(defaultRoles, values.roles)) admin.roles = roleIds;

    dispatch(
      !item
        ? createAdmin({ ...values, roles: roleIds })
        : updateAdmin({ id, admin })
    ).then((res) => {
      setSubmitLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif("admin success", "success");
      } else showNotif(res.payload.data.message, "error");
    });
  };
  const validate = Yup.object().shape({
    fullName: Yup.string().required("Full Name is required"),
    email: Yup.string().email("Email is invalid").required("Email is required"),
    password: Yup.string()
      .min(8, "Password is too short - should be 8 chars minimum.")
      .matches(/[a-zA-Z]/, "Password can only contain Latin letters."),
    roles: Yup.array(),
  });

  const optionsRoles = roles?.map((role) => {
    return { label: role.name, value: role._id };
  });

  const defaultRoles = roles
    ?.map((role) => {
      return item?.roles.some((r) => r._id === role._id)
        ? { label: role.name, value: role._id }
        : null;
    })
    .filter((role) => !!role);

  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          fullName: item?.fullName || "",
          email: item?.email || "",
          password: "",
          passwordConfirm: "",
          roles: defaultRoles || [],
        }}
        validationSchema={validate}
        onSubmit={(values) => {
          submitForm(values);
        }}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <div className="form-row">
                  <InputText
                    label="Full Name"
                    placeholder="Add your first name"
                    required={true}
                    name="fullName"
                    type="text"
                  />

                  <InputText
                    label="Email"
                    placeholder="Add your Email"
                    required={true}
                    name="email"
                    type="email"
                  />
                </div>

                <InputSelectMultiple
                  label="Roles"
                  placeholder="Select Roles"
                  required={true}
                  options={optionsRoles}
                  name="roles"
                  defaultValue={defaultRoles}
                />

                {!item && (
                  <div className="form-row">
                    <InputText
                      label="Password"
                      placeholder="Add your Password"
                      required={true}
                      name="password"
                      type="password"
                    />
                    <InputText
                      label="Confirm Password"
                      placeholder="Confirm your Password"
                      required={true}
                      name="passwordConfirm"
                      type="password"
                    />
                  </div>
                )}
              </div>
              <FormButton
                label={submitLoading ? <Loading /> : `${type} admin`}
              />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default AdminFormHandler;
