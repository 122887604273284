import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getExercices = createAsyncThunk(
  "exercice/getExercices",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, sort: { order: 1 } };
      const { data } = await getData("admin/exercices", params);
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createExercice = createAsyncThunk(
  "exercice/createExercice",
  async (exercice, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/exercices", exercice);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteExercice = createAsyncThunk(
  "exercice/deleteExercice",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/exercices", id);
      return { id };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateExercice = createAsyncThunk(
  "exercice/updateExercice",
  async ({ id, exercice }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/exercices", id, exercice);
      return data.payload.exercice;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const reaoderExercices = createAsyncThunk(
  "exercice/reorder",
  async (orders, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/exercices/update-order", "", {
        orders,
      });
      return data;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  exercices: [],
  meta: null,
  error: null,
};

const exercicesSlice = createSlice({
  name: "exercice",
  initialState,
  reducers: {},
  extraReducers: {
    //get exercices
    [getExercices.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getExercices.fulfilled]: (state, { payload }) => {
      state.exercices = payload.payload.exercices;
      state.meta = payload.meta;
      state.loading = false;
    },
    [getExercices.rejected]: (state, { payload }) => {
      state.error = payload;
      state.exercices = [];
      state.loading = false;
    },
    [deleteExercice.fulfilled]: (state, { payload }) => {
      const { id } = payload;
      state.exercices = state.exercices.filter(
        (exercice) => exercice._id !== id
      );
    },
  },
});

export const {} = exercicesSlice.actions;

export default exercicesSlice.reducer;
