import React from "react";
import { ErrorMessage, useField } from "formik";

function InputText({ label, placeholder, required, ...props }) {
  const [field, meta] = useField(props);

  return (
    <div className="textInpulItem customInput">
      <label className="label">
        {label}
        {required && <span>*</span>}
      </label>
      <input
        placeholder={placeholder}
        autoComplete="off"
        {...props}
        {...field}
        className={meta.touched && meta.error ? "is-invalid" : ""}
      />
      <ErrorMessage component="div" name={field.name} className="error" />
    </div>
  );
}

export default InputText;
