export const VERBS = {
  READ: "read",
  CREATE: "create",
  UPDATE: "update",
  DELETE: "delete",
  ALL: "*",
};
export const RESOURCES = {
  ADMINS: "admins",
  USERS: "users",
  LANGUAGES: "languages",
  LESSONS: "lessons",
  ROLES: "roles",
  REPORTS: "reports",
  REPORT_REASONS: "reportreasons",
  IMAGES: "images",
  IMAGES_CATEGORIES: "image-categories",
  EXERCISES: "exercices",
  EXERCISES_TYPES: "exercicetypes",
  QUESTIONS: "questions",
  QUESTION_TYPES: "question-types",
  QUESTION_BLOCKS: "question-blocks",
  QUESTION_BLOCK_TYPES: "question-block-types",
  REVISIONS: "revisions",
  VERSIONS: "versions",
  NOTIFICATION: "notifications",
  LEVELS: "levels",
  KEYWORDS: "keywords",
  COURSES: "courses",
  BADGES: "badges",
  COUNTRIES: "countries",
};
