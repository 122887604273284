import React from "react";
import { getString } from "../../../heplers/getString";

function TableColor({ column, data }) {
  const color = getString(data[column?.value]);
  const className = column?.className || "";
  return (
    <div
      className={className}
      style={{
        backgroundColor: color,
        width: "fit-content",
        padding: "2px",
        borderRadius: "5px",
        color: "white",
      }}
    >
      {color}
    </div>
  );
}

export default TableColor;
