import * as Yup from "yup";

export const ImageYupValidation = () =>
  Yup.lazy((value) => {
    switch (typeof value) {
      case "object":
        return Yup.object().required("Image is Required");
      case "string":
        return Yup.string().required("Image is Required");
      default:
        return Yup.mixed().required("Image is Required");
    }
  });
