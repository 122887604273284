import React from "react";

function GlobalColumnControll({ columnFields, setSelectedFields }) {
  const selectedFields = (id) => {
    setSelectedFields(
      columnFields.map((col) =>
        col?.id == id ? { ...col, show: !col?.show || false } : { ...col }
      )
    );
  };
  return (
    <>
      <div className="globalColumnControll">
        {columnFields?.map((filed) => {
          return filed?.show !== undefined ? (
            <div className="column" key={filed?.id}>
              {filed.heading}
              <div className="switch_box box_1 column-row">
                <input
                  type="checkbox"
                  className="switch_1"
                  onChange={() => selectedFields(filed?.id)}
                  defaultChecked={filed?.show}
                />
              </div>
            </div>
          ) : null;
        })}
      </div>
    </>
  );
}

export default GlobalColumnControll;
