import { defineAbility } from "@casl/ability";

const fn = (isAuth, user) =>
  defineAbility((can) => {
    if (!isAuth && !user) {
      return;
    }
    const userPermissions = user?.roles
      ?.map((role) => role?.permissions)
      .flat(1);

    if (isAuth) {
      userPermissions &&
        userPermissions?.forEach((p) => {
          p.verbs.forEach((verb) => {
            const action = verb === "*" ? "manage" : verb;
            return can(action, p.resource);
          });
        });
    }
  });

export default fn;
