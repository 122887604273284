import React, { useCallback } from "react";
import { useDropzone } from "react-dropzone";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import cloudIcon from "./../../../../assets/icons/browserUpload.png";

function UploadSection({ onSelect }) {
  const { showNotif } = useApiErrorContext();

  const onDrop = useCallback((acceptedFiles) => {
    const file = acceptedFiles[0];
    if (file?.size >= 2000000) {
      showNotif("File Too Large", "error");
      return;
    }
    onSelect(file);
  }, []);
  const { getRootProps, getInputProps, isDragActive } = useDropzone({
    onDrop,
    noClick: true,
  });

  return (
    <div className="library__content-upload">
      <label
        {...getRootProps()}
        className="library__content-upload-place"
        htmlFor="browserUpload"
        style={isDragActive ? { border: "3px dashed #107c10" } : {}}
      >
        <p className="library__content-upload-place-icon">
          <img src={cloudIcon} alt="error" />
        </p>
        {isDragActive ? (
          <p className="library__content-upload-place-description">
            Drop the files here ...
          </p>
        ) : (
          <p className="library__content-upload-place-description">
            <span>Click to upload</span> or drag and drop SVG, PNG, JPG or GIF
            (max. 800x400px)
          </p>
        )}
        <div className="submit-button"> Browser </div>
      </label>
      <input
        type="file"
        id="browserUpload"
        {...getInputProps()}
        accept="image/*"
      />
    </div>
  );
}

export default UploadSection;
