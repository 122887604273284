import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  library: false,
  file: null,
};

const librarySlice = createSlice({
  name: "library",
  initialState,
  reducers: {
    setToggleLibrary(state, {}) {
      state.library = !state.library;
    },
    closeLibrary(state, {}) {
      state.library = false;
    },
    openLibrary(state, {}) {
      state.library = true;
    },
    setFile(state, { payload }) {
      state.file = payload;
    },
    resetFile(state, { payload }) {
      state.file = null;
    },
  },
});

export const {
  setToggleLibrary,
  closeLibrary,
  openLibrary,
  setFile,
  resetFile,
} = librarySlice.actions;

export default librarySlice.reducer;
