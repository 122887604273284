import AbilityProvider from '../context/abilityContext';
import { ApiErrorProvider } from '../context/apiErrorContext';
import { AuthProvider } from '../context/authContext';
import { ModalFormProvider } from '../context/modalFormContext';
import ErrorLayout from '../layouts/errorLayout/ErrorLayout';
import routes, { RenderRoutes } from '../routes';

const App = () => {
  return (
    <div className="App">
      <ApiErrorProvider>
        <ErrorLayout>
          <AuthProvider>
            <AbilityProvider>
              <ModalFormProvider>
                <RenderRoutes routes={routes} />
              </ModalFormProvider>
            </AbilityProvider>
          </AuthProvider>
        </ErrorLayout>
      </ApiErrorProvider>
    </div>
  );
};

export default App;
