import menuIcon from "./../../assets/icons/Menu.png";
import messageIcon from "./../../assets/icons/Message - 2.png";
import notificationIcon from "./../../assets/icons/Notification - 4.png";
import trophyIcon from "./../../assets/icons/trophy 1.png";
import reportIcon from "./../../assets/icons/Report.png";
import QuestionTypeIcon from "./../../assets/icons/Question.png";
import levelIcon from "./../../assets/icons/Scan - 2.png";
import exerciceTypeIcon from "./../../assets/icons/Checklist2.png";
import startIcon from "./../../assets/icons/Star.png";
import twoUserIcon from "./../../assets/icons/Two-user.png";
import workcon from "./../../assets/icons/Work.png";
import rolesIcon from "./../../assets/icons/roles.png";

import { v4 } from "uuid";
import { PATH } from "./paths";
import { RESOURCES, VERBS } from "../permission";

export const PAGES = [
  { id: v4(5), icon: menuIcon, label: "Dashboard", link: PATH.DASHBOARD },
  {
    id: v4(5),
    icon: rolesIcon,
    label: "Roles",
    link: PATH.ROLES,
    permissions: { verb: VERBS.READ, resource: RESOURCES.ROLES },
  },
  {
    id: v4(5),
    icon: twoUserIcon,
    label: "User Types",
    permissions: { verb: VERBS.READ, resource: RESOURCES.USERS },
    routes: [
      {
        label: "admins",
        link: PATH.ADMINS,
        permissions: { verb: VERBS.READ, resource: RESOURCES.ADMINS },
      },
      {
        label: "users",
        link: PATH.USERS,
        permissions: { verb: VERBS.READ, resource: RESOURCES.USERS },
      },
    ],
  },
  {
    id: v4(5),
    icon: workcon,
    label: "courses",
    link: PATH.CHAPTERS,
    permissions: { verb: VERBS.READ, resource: RESOURCES.COURSES },
  },
  {
    id: v4(5),
    icon: messageIcon,
    label: "Revision",
    link: PATH.REVISION,
    permissions: { verb: VERBS.READ, resource: RESOURCES.REVISIONS },
  },
  {
    id: v4(5),
    icon: notificationIcon,
    label: "Notifications",
    link: PATH.NOTIFICATIONS,
    permissions: { verb: VERBS.READ, resource: RESOURCES.NOTIFICATION },
  },
  {
    id: v4(5),
    icon: trophyIcon,
    label: "Badges",
    link: PATH.BADGES,
    permissions: { verb: VERBS.READ, resource: RESOURCES.BADGES },
  },
  {
    id: v4(5),
    icon: exerciceTypeIcon,
    label: "Exercice Types",
    link: PATH.EXERCICETYPE,
    permissions: { verb: VERBS.READ, resource: RESOURCES.EXERCISES_TYPES },
  },
  {
    id: v4(5),
    icon: levelIcon,
    label: "Library",
    link: PATH.LIBRARY,
    permissions: { verb: VERBS.READ, resource: RESOURCES.IMAGES_CATEGORIES },
  },
  {
    id: v4(5),
    icon: QuestionTypeIcon,
    label: "Question Types",
    link: PATH.QUESTIONSTYPE,
    permissions: { verb: VERBS.READ, resource: RESOURCES.QUESTION_TYPES },
  },
  {
    id: v4(5),
    icon: startIcon,
    label: "Keywords",
    link: PATH.KEYWORDS,
    permissions: { verb: VERBS.READ, resource: RESOURCES.KEYWORDS },
  },
  {
    id: v4(5),
    icon: reportIcon,
    label: "Report",
    link: PATH.REPORT,
    permissions: { verb: VERBS.READ, resource: RESOURCES.REPORTS },
  },
  {
    id: v4(5),
    icon: workcon,
    label: "Countries",
    link: PATH.COUNTRIES,
    permissions: { verb: VERBS.READ, resource: RESOURCES.COUNTRIES },
  },
];
