import { Form, Formik } from "formik";
import React, { useEffect, useState } from "react";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import { useParams } from "react-router-dom";
import {
  createExercice,
  getExercices,
  updateExercice,
} from "../../../../store/slices/exercices";
import { getExerciceTypes } from "../../../../store/slices/exerciceTypes";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import Loading from "../../../../components/loadin/Loading";

function ExerciceFormHandler() {
  const { exercicesType } = useSelector((state) => state.exercicesTypes);
  const { hidePopup, id, item, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const { idLesson } = useParams();
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    title: Yup.string().required("Exercice Title is required"),
    exerciceTypeId: Yup.string().required("Exercice Type is required"),
    duration: Yup.number().required("Duration is required"),
  });

  useEffect(() => {
    if (exercicesType.length == 0) dispatch(getExerciceTypes());
  }, []);

  let query = { lessonId: idLesson };

  const options = exercicesType?.map((exercicesType) => {
    return {
      label: exercicesType.title,
      value: exercicesType._id,
    };
  });

  const submitForm = (values) => {
    const newExercice = {
      ...values,
      ...query,
    };
    setLoading(true);
    dispatch(
      item
        ? updateExercice({ id, exercice: values })
        : createExercice(newExercice)
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`exercice ${type}ed successfully`, "success");
        dispatch(getExercices(query));
      } else showNotif(res.payload.data.message, "ERROR");
    });
  };

  return (
    <div className="createAdminForm customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          exerciceTypeId: item?.exerciceTypeId?._id || "",
          duration: item?.duration || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Exercise Title"
                  placeholder="Add your first name"
                  required={true}
                  name="title"
                  type="text"
                />
                <InputText
                  label="Duration"
                  placeholder="Add your duration"
                  required={true}
                  name="duration"
                  type="number"
                />
                <InputSelect
                  label="Exercise type"
                  placeholder="Select Your Counry"
                  required={true}
                  name="exerciceTypeId"
                  options={options}
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} Exercise`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ExerciceFormHandler;
