import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getImages = createAsyncThunk(
  "images/getImages",
  async ({ categoryID, params = {} }, { rejectWithValue }) => {
    try {
      params = { ...params, limit: params?.limit || 10 };
      const { data } = await getData(
        "admin/image-categories/" + categoryID + "/images",
        params
      );
      return { data: data.payload.images, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createImage = createAsyncThunk(
  "images/createImage",
  async ({ image, categoryID }, { rejectWithValue }) => {
    try {
      const { data } = await createOne(
        "admin/image-categories/" + categoryID + "/images",
        image
      );
      return data.payload.image;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateImage = createAsyncThunk(
  "images/updateImage",
  async ({ id, image }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/images", id, image);
      return data.payload.image;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  images: [],
  meta: null,
  error: null,
};

const imagesSlice = createSlice({
  name: "images",
  initialState,
  reducers: {
    resetImages(state) {
      state.images = [];
    },
  },
  extraReducers: {
    [getImages.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getImages.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.images = data;
      state.meta = meta;
      state.loading = false;
    },
    [getImages.rejected]: (state, { payload }) => {
      state.error = payload;
      state.images = [];
      state.loading = false;
    },
    [updateImage.fulfilled]: (state, { payload }) => {
      state.images = state.images.map((image) =>
        image._id == payload._id ? { ...payload } : { ...image }
      );
    },
    [createImage.fulfilled]: (state, { payload }) => {
      state.images = [...state.images, payload];
      state.meta = { ...state.meta, totalDocs: state?.meta?.totalDocs + 1 };
    },
  },
});

export const { resetImages } = imagesSlice.actions;

export default imagesSlice.reducer;
