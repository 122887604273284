import TableAction from "../components/table/components/tableAction";
import TableArray from "../components/table/components/tableArray";
import TableBoolean from "../components/table/components/tableBoolean";
import TableColor from "../components/table/components/tableColor";
import TableDate from "../components/table/components/tableDate";
import TableDndIcon from "../components/table/components/tableDndIcon";
import TableImage from "../components/table/components/tableImage";
import TableRadioIcon from "../components/table/components/tableRadioIcon";
import TableString from "../components/table/components/tableString";
import TableRoles from "../components/table/components/tableRoles";
import TableCheckbox from "../components/table/components/tablecheckbox";

export const TableColumnType = {
  str: TableString,
  dnd: TableDndIcon,
  action: TableAction,
  radio: TableRadioIcon,
  img: TableImage,
  date: TableDate,
  bool: TableBoolean,
  array: TableArray,
  color: TableColor,
  roles: TableRoles,
  checkbox: TableCheckbox
};
