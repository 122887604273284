import React, { createContext, useContext, useReducer } from "react";

const initialState = {
  show: false,
  message: "",
  type: "",
};

const apiErrorContext = createContext({
  ...initialState,
});

const reducer = (state, action) => {
  switch (action.type) {
    case "show":
      return {
        ...state,
        message: action.payload?.message,
        type: action.payload.type,
        show: true,
      };

    case "hide":
      return {
        ...initialState,
      };

    default:
      return { ...state };
  }
};

function ApiErrorProvider(props) {
  const [state, dispatch] = useReducer(reducer, initialState);
  const showNotif = (message, type = "ERROR") => {
    dispatch({
      type: "show",
      payload: {
        message,
        type,
      },
    });
  };

  const hideNotif = () => {
    dispatch({
      type: "hide",
      payload: {
        type: "ERROR",
      },
    });
  };

  return (
    <apiErrorContext.Provider value={{ ...state, showNotif, hideNotif }}>
      {props.children}
    </apiErrorContext.Provider>
  );
}

const useApiErrorContext = () => useContext(apiErrorContext);

export { useApiErrorContext, ApiErrorProvider };
