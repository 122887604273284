import { v4 } from "uuid";
import { VERBS } from "./permission";

export const usersListColumns = [
  { id: v4(5), type: "radio" },
  {
    id: v4(5),
    heading: "User ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(5), heading: "First Name", value: "firstName", show: true },
  { id: v4(5), heading: "Last Name", value: "lastName", show: true },
  { id: v4(5), heading: "Phone Number", value: "phone", show: true },
  { id: v4(5), heading: "Email", value: "email", className: "higlightRow" },
  {
    id: v4(5),
    heading: "Country",
    value: "countryCode",
    nestedLabel: "name",
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(5), heading: "Action", type: "action" },
];

export const adminsListColumns = [
  { id: v4(5), type: "radio" },
  {
    id: v4(5),
    heading: "User ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  {
    id: v4(5),
    heading: "Full Name",
    value: "fullName",
    sortedBy: true,
    show: true,
  },
  {
    id: v4(5),
    heading: "Roles",
    value: "roles",
    type: "roles",
    sortedBy: true,
    show: true,
  },
  {
    id: v4(5),
    heading: "Email",
    value: "email",
    sortedBy: true,
    className: "higlightRow",
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(5), heading: "Action", type: "action" },
];

export const lessonsListColumns = [
  { id: v4(10), type: "dnd" },
  {
    id: v4(10),
    heading: "Lesson ID",
    value: "_id",
    show: true,
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Order", value: "order", show: true },
  { id: v4(10), heading: "Title", value: "title", show: true },
  { id: v4(10), heading: "Subtitle", value: "subtitle", show: true },
  { id: v4(10), heading: "Duration", value: "time", show: true },
  {
    id: v4(10),
    heading: "Level",
    value: "levelId",
    nestedLabel: "title",
    show: true,
    className: "specialTd arText",
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const exerciceListColumns = [
  { id: v4(10), type: "dnd" },
  {
    id: v4(10),
    heading: "Exercice ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Order", value: "order", show: true },
  {
    id: v4(10),
    heading: "Lesson",
    value: "lessonId",
    nestedLabel: "title",
    show: true,
  },
  { id: v4(10), heading: "Title", value: "title", show: true },
  {
    id: v4(10),
    heading: "Exercice Type",
    value: "exerciceTypeId",
    nestedLabel: "title",
    special: true,
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const questionListColumns = [
  { id: v4(10), type: "dnd" },
  {
    id: v4(10),
    heading: "Question ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Order", value: "order", show: true },
  { id: v4(10), heading: "Name", value: "title", show: true },
  {
    id: v4(10),
    heading: "Exercise",
    value: "exerciceId",
    nestedLabel: "title",
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const exerciceTypeListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Exercise ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Title", value: "title", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const levelListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Exercise ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Order", value: "order" },
  { id: v4(10), heading: "Title", value: "title", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const questionTypeListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Question ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Title", value: "title", show: true },
  { id: v4(10), heading: "Color", value: "color", type: "color", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const keywordsListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "keyword ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Sentence", value: "sentence", show: true },
  { id: v4(10), heading: "Exemple", value: "example", show: true },
  { id: v4(10), heading: "Translate", value: "translate", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const revesionsListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Revesion ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Title", value: "title", show: true },
  { id: v4(10), heading: "Text", value: "text", show: true },
  {
    id: v4(10),
    heading: "Keyword",
    value: "keywordId",
    nestedLabel: "sentence",
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const notificationsListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Notification ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Title", value: "title", show: true },
  { id: v4(10), heading: "Content", value: "body", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
];

export const countriesListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Country ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  {
    id: v4(10),
    heading: "Icon",
    value: "flag",
    type: "img",
    className: "flag",
    show: true,
  },
  { id: v4(10), heading: "Name", value: "name", show: true },
  { id: v4(10), heading: "Code", value: "code", show: true },
  {
    id: v4(10),
    heading: "Verification type",
    value: "isEmailRegistrationAvailable",
    type: "bool",
    true: { label: "by email" },
    false: { label: "by sms" },
    show: true,
  },
  { id: v4(10), heading: "Abbrevations", value: "ISOCode", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const reportsListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Report ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Label", value: "label", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const userReportsListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Report ID",
    value: "_id",
    className: "higlightRow",
    show: false,
  },
  {
    id: v4(10),
    heading: "issue",
    value: "reason",
    type: "array",
    show: true,
    className: "specialTd",
  },
  {
    id: v4(10),
    heading: "User",
    value: "reportedBy",
    nestedLabel: "firstName",
    show: true,
  },
  { id: v4(10), heading: "Question", value: "questionId", show: false },
  { id: v4(10), heading: "Revision", value: "revisionId", show: false },
  { id: v4(10), heading: "description", value: "description", show: true },
  {
    id: v4(10),
    heading: "Status",
    value: "isClosed",
    type: "bool",
    true: { label: "Close", className: "userRportClose" },
    false: { label: "Open", className: "userRportOpen" },
    show: true,
  },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const searchListColumns = [
  { id: v4(10), type: "radio" },
  {
    id: v4(10),
    heading: "Question",
    value: "questionName",
    className: "higlightRow",
    show: true,
  },
  { id: v4(10), heading: "Exercice", value: "exerciseName", show: true },
  { id: v4(10), heading: "Lesson", value: "lessonName", show: true },
  { id: v4(10), heading: "Chapter", value: "courseName", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(10), heading: "Action", type: "action" },
];

export const badgeListColumns = [
  { id: v4(5), type: "radio" },
  {
    id: v4(5),
    heading: "Badge ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(5), heading: "Order", value: "order", show: true },
  {
    id: v4(5),
    heading: "Icon",
    value: "imageUrl",
    type: "img",
    className: "flag",
    show: true,
  },
  { id: v4(5), heading: "Capacity", value: "maxAttendees", show: true },
  { id: v4(5), heading: "Up", value: "studentCountUp", show: true },
  { id: v4(5), heading: "Down", value: "studentCountDown", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(5), heading: "Action", type: "action" },
];

const ACTIONS = (verbs) =>
  verbs?.map((verb) => ({
    id: v4(5),
    heading: verb === VERBS.ALL ? "All" : verb,
    value: verb,
    show: true,
    type: "checkbox",
  }));

export const rolesFormColumn = (verbs) => {
  const actions = ACTIONS(verbs);
  if (!actions)
    return [
      {
        id: v4(5),
        type: "text",
        heading: "Resources/Actions",
        value: "resources",
      },
    ];
  return [
    {
      id: v4(5),
      type: "text",
      heading: "Resources/Actions",
      value: "resources",
    },
    ...actions,
  ];
};

export const rolesListColumns = [
  {
    id: v4(5),
    heading: "Role ID",
    value: "_id",
    className: "higlightRow",
    show: true,
  },
  { id: v4(5), heading: "name", value: "name", show: true },
  {
    id: v4(5),
    heading: "CreatedAt",
    value: "createdAt",
    type: "date",
    show: false,
  },
  {
    id: v4(5),
    heading: "UpdatedAt",
    value: "updatedAt",
    type: "date",
    show: false,
  },
  { id: v4(5), heading: "Action", type: "action" },
];
