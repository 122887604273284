import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getUserReports = createAsyncThunk(
  "report/getUserReports",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/reports", params);
      return { data: data.payload.userReports, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getReports = createAsyncThunk(
  "report/getReports",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params };
      const { data } = await getData("admin/report-reasons", params);
      return { data: data.payload.reportReasons, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createReport = createAsyncThunk(
  "report/createReport",
  async (report, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/report-reasons", report);
      return data.payload.reportReason;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteReport = createAsyncThunk(
  "report/deleteReport",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/report-reasons", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const closeUserReport = createAsyncThunk(
  "report/closeUserReport",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/reports/close", { reportId: id });
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteUserReport = createAsyncThunk(
  "report/deleteUserReport",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/reports", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateReport = createAsyncThunk(
  "report/updateReport",
  async ({ id, report }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/report-reasons", id, report);
      return data.payload.reportReason;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const getDetails = createAsyncThunk(
  "report/getDetails",
  async (params, { rejectWithValue }) => {
    try {
      const { data } = await getData("admin/details", params);
      return data.payload;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: null,
  reports: [],
  userReports: [],
  meta: null,
  userMeta: null,
  userLoading: null,
};

const reportsSlice = createSlice({
  name: "reports",
  initialState,
  reducers: {},
  extraReducers: {
    [getReports.pending]: (state) => {
      state.loading = true;
    },
    [getReports.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.reports = data;
      state.meta = meta;
      state.loading = false;
    },
    [getReports.rejected]: (state, { payload }) => {
      state.reports = [];
      state.loading = false;
    },
    [getUserReports.pending]: (state, { payload }) => {
      state.userLoading = true;
    },
    [getUserReports.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.userReports = data;
      state.userMeta = meta;
      state.userLoading = false;
    },
    [getUserReports.rejected]: (state, { payload }) => {
      state.userLoading = false;
      state.userReports = [];
    },
    [createReport.fulfilled]: (state, { payload }) => {
      state.reports = [...state.reports, payload];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteReport.fulfilled]: (state, { payload }) => {
      state.reports = state.reports?.filter((report) => report._id !== payload);
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
    [updateReport.fulfilled]: (state, { payload }) => {
      state.reports = state.reports?.map((report) =>
        report._id == payload._id ? { ...payload } : { ...report }
      );
    },
    [closeUserReport.fulfilled]: (state, { payload }) => {
      state.userReports = state.userReports?.map((report) =>
        report._id == payload ? { ...report, isClosed: true } : { ...report }
      );
    },
    [deleteUserReport.fulfilled]: (state, { payload }) => {
      state.userReports = state.userReports?.filter(
        (report) => report._id !== payload
      );
    },
  },
});

export const {} = reportsSlice.actions;

export default reportsSlice.reducer;
