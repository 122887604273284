import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";
import InputSelect from "../../../../components/formInputTypes/inputSelect/InputSelect";
import { useDispatch, useSelector } from "react-redux";
import {
  createChapter,
  updateChapter,
} from "../../../../store/slices/chapters";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import { useModalFormContext } from "../../../../context/modalFormContext";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import InputLibaray from "../../../../components/formInputTypes/inputLibrary/inputLibaray";
import { ImageYupValidation } from "../../../../constant/yupSchemas/imageSchema";

export const statusOptions = [
  { label: "open", value: "true" },
  { label: "close", value: "false" },
];

function ChapterFormHandler() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, item, id, type } = useModalFormContext();
  const { currentLanguage } = useSelector((state) => state.languages);

  const validate = Yup.object().shape({
    title: Yup.string().required("Chapter Name is required"),
    shortDescription: Yup.string().min(10).required("Description is required"),
    isEnable: Yup.boolean().required("status is required"),
    image: ImageYupValidation(),
  });

  const submitForm = (values) => {
    const { title, shortDescription, isEnable, image } = values;
    setLoading(true);
    dispatch(
      item
        ? updateChapter({
            id,
            chapter: convertObjectToFormData({
              title,
              shortDescription,
              isEnable,
              imageId: image?._id,
            }),
          })
        : createChapter(
            convertObjectToFormData({
              title,
              shortDescription,
              isEnable,
              imageId: image?._id,
              languageId: currentLanguage?._id,
            })
          )
    ).then((res) => {
      if (!res.error) {
        hidePopup();
        showNotif(`chapter ${type}ed successfully`, "success");
      } else showNotif(res?.payload?.data?.message, "error");
      setLoading(false);
    });
  };

  return (
    <div className="createChapterForm customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          shortDescription: item?.shortDescription || "",
          isEnable: item?.isEnable.toString() || "",
          image: item?.image || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <div className="form-row">
                  <InputText
                    label="Chapter Name"
                    placeholder="Enter your chapter name"
                    required={true}
                    name="title"
                    type="text"
                  />

                  <InputText
                    label="Description"
                    placeholder="Enter your description"
                    required={true}
                    name="shortDescription"
                    type="text"
                  />
                </div>

                <InputLibaray
                  label="Image"
                  placeholder="Select your image"
                  required={true}
                  name="image"
                  formik={formik}
                  deafultFile={item?.image}
                />

                <InputSelect
                  options={statusOptions}
                  label="Status"
                  placeholder="Select your Status"
                  required={false}
                  name="isEnable"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} chapter`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default ChapterFormHandler;
