import React, { useState } from "react";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import { Form, Formik } from "formik";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import Loading from "../../../../components/loadin/Loading";
import { updateSearch } from "../../../../store/slices/globalSearch";

function SearchForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, item, id } = useModalFormContext();

  const validate = Yup.object().shape({
    Text: Yup.string().required("Text is required"),
  });

  const submitForm = (values) => {
    const { Text } = values;
    setLoading(true);
    dispatch(updateSearch({ word: item, ids: id, replaceWith: Text })).then(
      (res) => {
        setLoading(false);
        if (!res.error) {
          hidePopup();
          showNotif(`question udpated successfully`, "success");
        } else showNotif(res?.payload?.data?.message, "error");
      }
    );
  };

  return (
    <div className="lessonForm customForm">
      <Formik
        initialValues={{
          Text: "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Replaced Word"
                  placeholder="Enter your word"
                  required={true}
                  name="Text"
                  type="text"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `Replace`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default SearchForm;
