import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getKeywords = createAsyncThunk(
  "keyword/getKeywords",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, limit: params?.limit || 10 };
      const { data } = await getData("admin/keywords", params);
      return { data: data.payload.keywords, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createKeyword = createAsyncThunk(
  "keyword/createKeyword",
  async (keyword, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/keywords", keyword);
      return data.payload.keyword;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteKeyword = createAsyncThunk(
  "keyword/deleteKeyword",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/keywords", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateKeyword = createAsyncThunk(
  "keyword/updateKeyword",
  async ({ id, keyword }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/keywords", id, keyword);
      return data.payload.keyword;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  keywords: [],
  meta: null,
  error: null,
};

const keywordsSlice = createSlice({
  name: "keywords",
  initialState,
  reducers: {},
  extraReducers: {
    [getKeywords.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getKeywords.fulfilled]: (state, { payload }) => {
      const { data, meta } = payload;
      state.keywords = data;
      state.meta = meta;
      state.loading = false;
    },
    [getKeywords.rejected]: (state, { payload }) => {
      state.error = payload;
      state.keywords = [];
      state.loading = false;
    },
    [createKeyword.fulfilled]: (state, { payload }) => {
      state.keywords = [...state.keywords, payload];
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs + 1 };
    },
    [deleteKeyword.fulfilled]: (state, { payload }) => {
      state.keywords = state.keywords.filter(
        (keyword) => keyword._id !== payload
      );
      state.meta = { ...state.meta, totalDocs: state.meta.totalDocs - 1 };
    },
    [updateKeyword.fulfilled]: (state, { payload }) => {
      state.keywords = state.keywords.map((keyword) =>
        keyword._id == payload._id ? { ...payload } : { ...keyword }
      );
    },
  },
});

export const {} = keywordsSlice.actions;

export default keywordsSlice.reducer;
