import React from "react";
import { useModalFormContext } from "../../context/modalFormContext";
import LanguageForm from "../../layouts/mainLayout/components/sideBar/components/languages/languageForm";
import AdminFormHandler from "../../views/admins/components/form/adminFormHandler";
import BadgeForm from "../../views/badges/components/form/badgeForm";
import ChapterFormHandler from "../../views/chapters/components/form/chapterFormHandler";
import CountryFormHandler from "../../views/countries/components/form/countriesFormHandler";
import ExerciceFormHandler from "../../views/exercices/components/form/exerciceFormHandler";
import ExerciceTypeFormHandler from "../../views/exerciceType/components/form/exerciceTypeFormHandler";
import SearchForm from "../../views/globalSearch/components/form/form";
import ImageForm from "../../views/images/components/form/form";
import KeywordFormHandler from "../../views/keywords/components/form/keywordFormHandler";
import LevelFormHandler from "../../views/lessonLevel/components/form/levelFormHandler";
import LessonFormHandler from "../../views/lessons/components/form/lessonFormHandler";
import FolderForm from "../../views/library/components/form/form";
import QuestionFormHandler from "../../views/questions/components/form/questionFormHandler";
import QuestionTypeFormHandler from "../../views/questions/questionType/components/form/questionTypeFormHandler";
import ReportFormHandler from "../../views/reports/components/form/reportFormHandler";
import RevisionFormHandler from "../../views/revision/components/form/revisionFormHandler";
import BanUserForm from "../../views/users/components/form/banUserForm";
import BanUserPreview from "../../views/users/components/form/banUserPreview";
import UserFormHandler from "../../views/users/components/form/userFormHandler";
import DuplicateLessonForm from "../../views/lessons/components/duplicateForm/duplicateFormHandler";
import closeIcon from "./../../assets/icons/CloseWhite.png";

const Forms = {
  adminForm: AdminFormHandler,
  userForm: UserFormHandler,
  banUser: BanUserForm,
  chapterForm: ChapterFormHandler,
  lessonForm: LessonFormHandler,
  exerciceForm: ExerciceFormHandler,
  exerciceTypeForm: ExerciceTypeFormHandler,
  levelForm: LevelFormHandler,
  questionTypeForm: QuestionTypeFormHandler,
  keywordForm: KeywordFormHandler,
  revisionForm: RevisionFormHandler,
  questionForm: QuestionFormHandler,
  countryForm: CountryFormHandler,
  reportForm: ReportFormHandler,
  searchForm: SearchForm,
  banUserPreview: BanUserPreview,
  badgeForm: BadgeForm,
  foldersLibraryForm: FolderForm,
  ImageForm: ImageForm,
  languageForm: LanguageForm,
  duplicateLessonForm: DuplicateLessonForm,
};

function ModalForm() {
  const { show, hidePopup, form, header } = useModalFormContext();
  const FormComponent = Forms[form] || React.Fragment;

  return (
    <>
      {show && <div className="backscreen" onClick={hidePopup}></div>}
      <div className={`modalForm ${show ? "show" : ""}`}>
        <div className="modalForm-content">
          <div className="modalForm__hedaer">
            <p>{header}</p>
            <img src={closeIcon} alt="error" onClick={hidePopup} />
          </div>
          <div className="modalForm__form">
            <FormComponent />
          </div>
        </div>
      </div>
    </>
  );
}

export default ModalForm;
