export const PATH = {
  LOGIN: "/login",
  DASHBOARD: "/dashboard",
  USERS: "/users",
  ADMINS: "/admins",
  CHAPTERS: "/chapters",
  LEVELS: "/chapters/:id/levels",
  LESSONS: "/chapters/:id/levels/:levelId/lessons",
  EXERCICES: "/chapters/:id/levels/:levelId/lessons/:idLesson/exercices",
  QUESTIONS:
    "/chapters/:id/levels/:levelId/lessons/:idLesson/exercices/:exerciceId/questions",
  QUESTION:
    "/chapters/:id/levels/:levelId/lessons/:idLesson/exercices/:exerciceId/questions/:questionId",
  CREATEQUESTION:
    "/chapters/:id/levels/:levelId/lessons/:idLesson/exercices/:exerciceId/questions/new",
  COUNTRIES: "/countries",
  REVISION: "/revision",
  NOTIFICATIONS: "/notifications",
  BADGES: "/badges",
  ROLES: "/roles",
  UPDATEROLE: "/roles/form/:id",
  CREATEROLE: "/roles/form",
  EXERCICETYPE: "/exercice-type",
  LIBRARY: "/library",
  IMAGES: "/library/:folderID/images",
  QUESTIONSTYPE: "/questions-type",
  KEYWORDS: "/keywords",
  REPORT: "/report",
  ERROR: "*",
};
