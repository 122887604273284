import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getLevels = createAsyncThunk(
  "level/getLevels",
  async (params = {}, { rejectWithValue }) => {
    try {
      params = { ...params, limit: 10 };
      const { data } = await getData("admin/levels", params);
      return { data: data.payload, meta: data.meta };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createLevel = createAsyncThunk(
  "level/createLevel",
  async (level, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/levels", level);
      return data.payload.level;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const deleteLevel = createAsyncThunk(
  "level/deleteLevel",
  async (id, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/levels", id);
      return id;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const updateLevel = createAsyncThunk(
  "level/updateLevel",
  async ({ id, level }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/levels", id, level);
      return data.payload.level;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  levels: [],
  error: null,
};

const levelsSlice = createSlice({
  name: "level",
  initialState,
  reducers: {
    resetLevels(state) {
      state.levels = [];
    },
    updateLocation(state, { payload }) {
      const id = payload;
      state.levels = state.levels.filter((level) => level?._id !== id);
    },
  },
  extraReducers: {
    [getLevels.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getLevels.fulfilled]: (state, { payload }) => {
      state.levels = payload.data.levels;
      state.loading = false;
    },
    [getLevels.rejected]: (state, { payload }) => {
      state.error = payload;
      state.levels = [];
      state.loading = false;
    },
    [createLevel.fulfilled]: (state, { payload }) => {
      if (payload?.isPublic == state.levels[0]?.isPublic)
        state.levels = [...state.levels, payload];
    },
    [deleteLevel.fulfilled]: (state, { payload }) => {
      state.levels = state.levels.filter((level) => level._id !== payload);
    },
    [updateLevel.fulfilled]: (state, { payload }) => {
      state.levels = state.levels.map((level) =>
        level._id == payload._id ? { ...payload } : { ...level }
      );
    },
  },
});

export const { resetLevels, updateLocation } = levelsSlice.actions;

export default levelsSlice.reducer;
