import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { findData } from "../../heplers/findData";
import {
  createOne,
  deleteOne,
  getData,
  updateOne,
} from "../../services/protectApi";

export const getChapters = createAsyncThunk(
  "chapter/getChapters",
  async (params = {}, { rejectWithValue }) => {
    try {
      const { data } = await getData("admin/courses", params);
      return { data: data?.payload?.courses, languageId: params?.languageId };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

export const createChapter = createAsyncThunk(
  "chapter/createChapter",
  async (ChapterData, { rejectWithValue }) => {
    try {
      const { data } = await createOne("admin/courses", ChapterData);
      return { data: data.payload.course };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const deleteChapter = createAsyncThunk(
  "chapter/deleteChapter",
  async ({ id, languageId }, { rejectWithValue }) => {
    try {
      const { data } = await deleteOne("admin/courses", id);
      return { id, languageId };
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);
export const updateChapter = createAsyncThunk(
  "chapter/updateChapter",
  async ({ id, chapter }, { rejectWithValue }) => {
    try {
      const { data } = await updateOne("admin/courses", id, chapter);
      return data.payload.course;
    } catch (error) {
      return rejectWithValue(error.response);
    }
  }
);

const initialState = {
  loading: "",
  chapters: [],
  error: null,
  createError: null,
  createLoading: "",
  createdChapter: null,
};

const chapterSlice = createSlice({
  name: "chapter",
  initialState,
  reducers: {
    updateLocation(state, { payload }) {
      const id = payload;
      state.chapters = state.chapters.filter((chapter) => chapter?._id !== id);
    },
  },
  extraReducers: {
    [getChapters.pending]: (state, { payload }) => {
      state.loading = true;
    },
    [getChapters.fulfilled]: (state, { payload }) => {
      state.chapters = payload?.data;
      state.loading = false;
    },
    [getChapters.rejected]: (state, { payload }) => {
      state.error = payload;
      state.chapters = [];
      state.loading = false;
    },
    [createChapter.pending]: (state, { payload }) => {
      state.createLoading = true;
    },
    [createChapter.fulfilled]: (state, { payload }) => {
      const { data } = payload;
      const { isPublic } = data;
      if (isPublic == state.chapters[0]?.isPublic)
        state.chapters = [...state.chapters, { ...data }];
      state.createLoading = false;
    },
    [createChapter.rejected]: (state, { payload }) => {
      state.createError = payload.data;
      state.createLoading = false;
    },
    [deleteChapter.fulfilled]: (state, { payload }) => {
      const { id, languageId } = payload;
      state.chapters = state.chapters?.filter((chapter) => chapter?._id !== id);
    },
    [updateChapter.fulfilled]: (state, { payload }) => {
      state.chapters = state.chapters?.map((chapter) =>
        chapter._id == payload._id ? { ...payload } : { ...chapter }
      );
    },
  },
});

export const { updateLocation } = chapterSlice.actions;

export default chapterSlice.reducer;
