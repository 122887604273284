import React, { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { useDispatch } from "react-redux";
import {
  getCategories,
  resetCategories,
} from "../../../../store/slices/categories";
import { FormButton } from "../../../../views/admins/components/form/adminFormHandler";
import LoadingView from "../../../loadingView/LoadingView";
import Search from "../../../search/Search";
import folderIcon from "./../../../../assets/icons/Vector 2.png";
import moreIcon from "./../../../../assets/icons/More.png";
import More from "../../../more/More";
import Paggination from "../../../paggination/Paggination";
import DataEmpty from "../../../dataEmpty/DataEmpty";

export const FolderItem = ({ openFolder, folder, actions }) => {
  const [moreOpen, setMoreOpen] = useState(false);
  return (
    <div
      className="library__content-library-folders-content-item"
      key={folder?._id}
    >
      {actions && (
        <span onClick={() => setMoreOpen(!moreOpen)} id="more">
          <img src={moreIcon} id="more" />
        </span>
      )}
      {moreOpen && (
        <More
          actionsList={actions}
          itemID={folder._id}
          setMoreOpen={setMoreOpen}
        />
      )}
      <div className="library__content-library-folders-content-item-frs">
        <img src={folderIcon} alt="" />
        <p className="arText">{folder?.name}</p>
      </div>
      <div className="library__content-library-folders-content-item-scs">
        <div
          className="library__content-library-folders-content-item-scs-button"
          onClick={() => openFolder(folder)}
        >
          <FormButton label="Open" />
        </div>
      </div>
    </div>
  );
};

function LibrarySection({ onClick }) {
  const dispatch = useDispatch();
  const [limit, setLimit] = useState(25);
  const [searchCategory, setSearchCategory] = useState("");
  const { categories, loading, meta } = useSelector(
    (state) => state.categories
  );
  const openFolder = (item) => onClick(item);

  const handleChange = (e, p) =>
    dispatch(
      getCategories({
        page: p,
        limit,
        search: searchCategory,
      })
    );

  useEffect(() => {
    dispatch(resetCategories());
    dispatch(getCategories({ search: searchCategory, limit }));
  }, [searchCategory, limit]);

  return (
    <div className="library__content-library">
      <div className="library__content-library-search">
        <Search
          placeholder="Search for a category or image"
          onChange={(v) => setSearchCategory(v)}
        />
      </div>
      <div className="library__content-library-folders">
        <LoadingView loading={loading}>
          <div className="library__content-library-folders-content">
            {categories?.length > 0 ? (
              categories.map((folder) => (
                <FolderItem openFolder={openFolder} folder={folder} key={folder.name} />
              ))
            ) : (
              <DataEmpty />
            )}
          </div>
        </LoadingView>
      </div>
      <div className="library__content-library-buttons-content">
        <Paggination
          onChange={handleChange}
          count={meta?.totalPages}
          setLimit={setLimit}
        />
      </div>
    </div>
  );
}

export default LibrarySection;
