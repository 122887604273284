import { Form, Formik } from "formik";
import * as Yup from "yup";
import { useState } from "react";
import { useDispatch } from "react-redux";
import Loading from "../../../../components/loadin/Loading";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import {
  createCategory,
  updateCategory,
} from "../../../../store/slices/categories";

function FolderForm() {
  const dispatch = useDispatch();
  const [loading, setLoading] = useState("");
  const { showNotif } = useApiErrorContext();
  const { hidePopup, id, item, type } = useModalFormContext();

  const validate = Yup.object().shape({
    name: Yup.string().required("folder Name is required"),
  });

  const submitForm = (values) => {
    setLoading(true);
    dispatch(
      item ? updateCategory({ id, category: values }) : createCategory(values)
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`folder ${type}ed successfully`, "success");
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  return (
    <div className="lessonForm customForm">
      <Formik
        initialValues={{
          name: item?.name || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <InputText
                  label="Folder Name"
                  placeholder="Enter your Folder name"
                  required={true}
                  name="name"
                  type="text"
                />
              </div>
              <FormButton label={loading ? <Loading /> : `${type} folder`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default FolderForm;
