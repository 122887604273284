import { Form, Formik } from "formik";
import React, { useState } from "react";
import InputText from "../../../../components/formInputTypes/inputText/InputText";
import { FormButton } from "../../../admins/components/form/adminFormHandler";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import InputUpload from "../../../../components/formInputTypes/inputUpload/inputUpload";
import { convertObjectToFormData } from "../../../../heplers/convertObjectToFormData";
import {
  createLesson,
  getLessons,
  updateLesson,
} from "../../../../store/slices/lessons";
import { useApiErrorContext } from "../../../../context/apiErrorContext";
import { useModalFormContext } from "../../../../context/modalFormContext";
import Loading from "../../../../components/loadin/Loading";
import { useParams } from "react-router-dom";
import InputLibaray from "../../../../components/formInputTypes/inputLibrary/inputLibaray";
import { ImageYupValidation } from "../../../../constant/yupSchemas/imageSchema";

function LessonFormHandler() {
  const { hidePopup, id, item, type } = useModalFormContext();
  const { showNotif } = useApiErrorContext();
  const [loading, setLoading] = useState("");
  const { levelId, courseId } = useParams();
  const dispatch = useDispatch();

  const validate = Yup.object().shape({
    title: Yup.string().required("Lesson Title is required"),
    subtitle: Yup.string().required("Subtitle is required"),
    image: ImageYupValidation(),
    time: Yup.number().required("duration is required"),
    intro: Yup.string().required("introduction is required"),
  });

  const query = { courseId, levelId };

  const submitForm = (values) => {
    const { title, subtitle, time, intro, image } = values;
    const lesson = { title, subtitle, time, intro, imageId: image?._id };
    setLoading(true);
    dispatch(
      !item
        ? createLesson(
            convertObjectToFormData({
              ...lesson,
              ...query,
            })
          )
        : updateLesson({
            id,
            lesson: convertObjectToFormData(lesson),
          })
    ).then((res) => {
      setLoading(false);
      if (!res.error) {
        hidePopup();
        showNotif(`lesson ${type}ed successfully`, "success");
        dispatch(getLessons({ ...query, isPublic: res?.payload?.isPublic }));
      } else showNotif(res?.payload?.data?.message, "error");
    });
  };

  return (
    <div className="lessonForm customForm">
      <Formik
        initialValues={{
          title: item?.title || "",
          subtitle: item?.subtitle || "",
          image: item?.thumbnail || "",
          time: item?.time || "",
          intro: item?.intro || "",
        }}
        validationSchema={validate}
        onSubmit={(values) => submitForm(values)}
      >
        {(formik) => {
          return (
            <Form>
              <div className="inputContainer">
                <div className="form-row">
                  <InputText
                    label="Lesson Title"
                    placeholder="Enter your lesson title"
                    required={true}
                    name="title"
                    type="text"
                  />

                  <InputText
                    label="Subtitle"
                    placeholder="Enter your subtitle"
                    required={true}
                    name="subtitle"
                    type="text"
                  />
                </div>
                <div className="form-row">
                  <InputLibaray
                    label="Lesson Image"
                    placeholder="Select your image"
                    required={true}
                    name="image"
                    formik={formik}
                    deafultFile={item?.thumbnail}
                  />

                  <InputText
                    label="Duration"
                    placeholder="Enter Your Duartion"
                    required={true}
                    name="time"
                    type="number"
                  />
                </div>
                <div className="form-row">
                  <InputText
                    label="Introduction"
                    placeholder="Enter Your Introduction"
                    required={true}
                    name="intro"
                    type="text"
                  />
                </div>
              </div>
              <FormButton label={loading ? <Loading /> : `${type} lesson`} />
            </Form>
          );
        }}
      </Formik>
    </div>
  );
}

export default LessonFormHandler;
